import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import GraphLine from '../components/graph-line';
import GraphBar from '../components/graph-bar';
import FormSelect from '../components/form-select';
import ApiWrapper from '../utils/api-wrapper';
import Utils from '../utils/utils';
import TableData from '../components/table-data';
import Invoice from '../components/invoice';
import Select from 'react-select'; 


export default inject("data")(withRouter(observer(class Monitor extends Component {
    
    constructor(props) {
        super(props);
        this.styleSelect = {
            control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
            })
        };
        this.state = {
            selected: null,
            currentInvoice: null,
            invoiceCount: 0,
            amountTotal: 0,
            monthlyProcessedCount: [],
            monthlyProcessedCountSec: [],
            monthlyProcessedAvgTime: [],
            monthlyProcessedAvgTimeSec: [],
            monthlyProcessedTotal: [],
            monthlyProcessedByUserUsers: [],
            monthlyProcessedByUserTotal: [],
            usersList: []
        }
        this.onChangeDropdownUser = this.onChangeDropdownUser.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.fetchUsers = this.fetchUsers.bind(this)
        this.dropdownSetState = this.dropdownSetState.bind(this)
        this.getUsersUsersTotal = this.getUsersUsersTotal.bind(this)
        this.selectionColumn = [
            { "name": "json.InvoiceNo", "evaluate": inv => inv.json ? inv.json.InvoiceNo : this.props.data.t.read("n_a"), "displayName": "invoices", "filter": "json.InvoiceNo", searchable: true },
            { "name": "json.InvoiceDate", "evaluate": inv => inv.json ? inv.json.InvoiceDate : this.props.data.t.read("n_a"), "displayName": "invoice.billing_date" },
            { "name": "json.DueDate", "evaluate": inv => inv.json && inv.json.DueDate ? inv.json.DueDate : this.props.data.t.read("n_a"), "displayName": "invoice.due_date" },
            { "name": "json.Total", "evaluate": inv => inv.json && inv.json.Total ? inv.json.Total : this.props.data.t.read("n_a"), "displayName": "invoice.total", "money": true }
        ]

        if (Utils.userHasPageAccess(this.props.data, "approval"))
            this.selectionColumn.push({ "name": "approver", "evaluate": inv => inv.json && inv.json.approver ? inv.json.approver.first_name + " " + inv.json.approver.last_name : this.props.data.t.read("n_a"), "displayName": "invoice.approver" })
        if (Utils.userHasPageAccess(this.props.data, "payments"))
            this.selectionColumn.push({ "name": "payment_status", "evaluate": inv => inv.payment ? inv.payment.status : this.props.data.t.read("n_a"), "displayName": "invoice.payment_status" })

        this.vendorsColumn = [
            { "name": "supplier_name", "evaluate": inv => inv?.supplier_name ? `${inv.supplier_name}` : "", "displayName": "vendors", "filter": "json.ListSupplier.0.Number", "money": false, searchable: true },
            { "name": "MTD", "evaluate": inv => inv?.MTD ? inv.MTD : this.props.data.t.read("n_a"), "displayName": "MTD", "money": true, "acronym": "acronym.MTD" },
            { "name": "YTD", "evaluate": inv => inv?.YTD ? inv.YTD : this.props.data.t.read("n_a"), "displayName": "YTD", "money": true, "acronym": "acronym.YTD" },
            { "name": "BYTD", "evaluate": inv => inv?.BYTD ? inv.BYTD : this.props.data.t.read("n_a"), "displayName": "BYTD", "money": true, "acronym": "acronym.MTD" },
            { "name": "PY", "evaluate": inv => inv?.PY ? inv.PY : this.props.data.t.read("n_a"), "displayName": "PY", "money": true, "acronym": "acronym.PY" }
        ]
        this.vendorsVariance = [
            { "name": "supplier_name", "evaluate": inv => inv?.supplier_name ? `${inv.supplier_name}` : "", "displayName": "vendors", "filter": "json.ListSupplier.0.Number", "money": false, searchable: true },
            { "name": "MTD", "evaluate": inv => inv?.MTD ? inv.MTD : this.props.data.t.read("n_a"), "displayName": "MTD", "money": true, "acronym": "acronym.MTD" },
            { "name": "AVGM", "evaluate": inv => inv?.AVGM ? inv.AVGM : this.props.data.t.read("n_a"), "displayName": "AvgM", "money": true, "acronym": "acronym.AvgM" },
            { "name": "VARIANCE", "evaluate": inv => inv?.VARIANCE ? `${inv.VARIANCE.toFixed(2)}%` : this.props.data.t.read("n_a"), "displayName": "Variance", "money": false }
        ]
        this.glsColumn = [{ "name": "_id", "evaluate": inv => inv?._id ? `${inv._id}` : "", "displayName": "gl", "filter": "json.ListItem.GLNatural", "money": false, searchable: true },
        ...this.vendorsColumn.filter(c => c.displayName !== "vendors")]
    }

    componentDidMount() {
        this.fetchDataInit("");
        this.fetchUsers();
    }

    fetchDataInit(username_selected) {
        ApiWrapper.fetch(this, `/stats/monitor?username_selected=${username_selected}`, "GET", {}, response => {
            const [users, usersTotal] = this.getUsersUsersTotal(response);
            this.dropdownSetState(response, usersTotal, users);
        });
    }

    fetchUsers() {
        ApiWrapper.fetch(this, `/client/usersDetail`, "GET", {}, response => {
            this.setState({
                ...this.state,
                usersList: response.data
            }
            )
        });
    }

    getUsersUsersTotal(response) {
        let users = [];
        let usersTotal = []

        for (let username in response.data["invoices_per_user"]) {
            users.push(username);
            usersTotal.push(response.data["invoices_per_user"][username]);
        }
        return [users, usersTotal]
    }

    dropdownSetState(response, usersTotal, processedByUsersUsers) {
        this.setState({
            ...this.state,
            invoiceCount: response.data["invoice_count"],
            amountTotal: parseInt(response.data["invoice_total"]),
            monthlyProcessedByUserUsers: processedByUsersUsers,
            monthlyProcessedByUserTotal: usersTotal,
            monthlyProcessedCount: response.data["monthly_processed"],
            monthlyProcessedAvgTime: response.data["monthly_avg_proc_time"],
            monthlyProcessedTotal: response.data["monthly_amounts"],
        });
    }

    fetchData(username_selected) {
        ApiWrapper.fetch(this, `/stats/monitor?username_selected=${username_selected}`, "GET", { "Hello": "hello" }, response => {
            const [users, usersTotal] = this.getUsersUsersTotal(response);
            this.dropdownSetState(response, usersTotal, users);
        }, null, false);
    }

    onChangeDropdownUser(value) {
        if (value === this.props.data.t.read("user")) {
            this.fetchData("")
        } else {
            this.fetchData(value)
        }
    }

    render() {
        return <div className="monitor-page">
            <div className="d-flex justify-content-between mb-4 pl-2">

                {/* Dropdown user selection */}
                <div className="d-flex flex-3">
                    <div className="mr-2 col-3">
                        <Select
                                    name="user"
                                    options={this.state.usersList.map( u => ({value: u.username, label: u.first_name +" "+ u.last_name } ) ) }
                                    styles={this.styleSelect}
                                    classNamePrefix="select"
                                    placeholder={this.props.data.t.read("user")}
                                    onChange={(choice) => this.onChangeDropdownUser(choice === null ? this.props.data.t.read("user") : choice.value)}
                        />
                    </div>
                </div>
                {/* Time selection
                        Not used, might be usefull later
                        <div className="flex-2 d-flex justify-content-end">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-sm btn-light">1{this.props.data.t.read("D")}</button>
                                <button type="button" className="btn btn-sm btn-light">1M</button>
                                <button type="button" className="btn btn-sm btn-light">1{this.props.data.t.read("Q")}</button>
                                <button type="button" className="btn btn-sm btn-primary">1{this.props.data.t.read("Y")}</button>
                            </div>
                        </div>
                        */}
            </div>
            {/* Line of figures */}
            <div className="d-flex justify-content-between">
                <div className="rounded-box">
                    <div className="huge-number">{this.state.invoiceCount}</div>
                    <div className="graph-data-label">{this.props.data.t.read("invoiceProcessedCount")}</div>
                </div>
                <div className="rounded-box">
                    <div className="huge-number">$ {this.state.amountTotal.toLocaleString()}</div>
                    <div className="graph-data-label">{this.props.data.t.read("invoiceProcessedTotal")}</div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="rounded-box">
                    <div style={{ height: 200 }}>
                        {this.state.monthlyProcessedCount.length > 0 ?
                            <GraphLine dataset={this.state.monthlyProcessedCount}
                                secondaryDataSet={this.state.monthlyProcessedCountSec} showYAxis={true}
                                color="#fe6888" id="monthly-processed" hardLine={true}
                                labels={[this.props.data.t.read('month.jan').toUpperCase(), this.props.data.t.read('month.feb').toUpperCase(), this.props.data.t.read('month.mar').toUpperCase(), this.props.data.t.read('month.apr').toUpperCase(), this.props.data.t.read('month.may').toUpperCase(), this.props.data.t.read('month.jun').toUpperCase(), this.props.data.t.read('month.jul').toUpperCase(), this.props.data.t.read('month.aug').toUpperCase(), this.props.data.t.read('month.sep').toUpperCase(), this.props.data.t.read('month.oct').toUpperCase(), this.props.data.t.read('month.nov').toUpperCase(), this.props.data.t.read('month.dec').toUpperCase()]} /> : null}
                    </div>
                    <div className="graph-data-label">{this.props.data.t.read("invoiceProcessedCount")}</div>
                </div>
                <div className="rounded-box">
                    <div style={{ height: 200 }}>
                        {this.state.monthlyProcessedTotal.length > 0 ?
                            <GraphBar dataset={this.state.monthlyProcessedTotal} horizontal={true} showYAxis={true}
                                label="$" id="monthly-invoice-processed"
                                color={["#ccb2ff", "#9ad0f5", "#a5dfdf", "#ffe198", "#9ad0f5", "#ffb1c1"]}
                                labels={[this.props.data.t.read('month.jan').toUpperCase(), this.props.data.t.read('month.feb').toUpperCase(), this.props.data.t.read('month.mar').toUpperCase(), this.props.data.t.read('month.apr').toUpperCase(), this.props.data.t.read('month.may').toUpperCase(), this.props.data.t.read('month.jun').toUpperCase(), this.props.data.t.read('month.jul').toUpperCase(), this.props.data.t.read('month.aug').toUpperCase(), this.props.data.t.read('month.sep').toUpperCase(), this.props.data.t.read('month.oct').toUpperCase(), this.props.data.t.read('month.nov').toUpperCase(), this.props.data.t.read('month.dec').toUpperCase()]} /> : null}
                    </div>
                    <div className="graph-data-label">{this.props.data.t.read("invoiceProcessedTotal")}</div>
                </div>
            </div>
            {Utils.userHasPageAccess(this.props.data, "reporting") &&
                <>
                    <div className="d-flex justify-content-between">
                        <TableData title={this.props.data.t.read("vendor_actuals")} t={this.props.data.t} availableColumns={this.vendorsColumn} url="/dataset/vendor_actuals" selection={(s) => this.setState((prev) => ({ ...prev, selected: s }))} />
                        <TableData title={this.props.data.t.read("account_actuals")} t={this.props.data.t} availableColumns={this.glsColumn} url="/dataset/gl_actuals" selection={(s) => this.setState((prev) => ({ ...prev, selected: s }))} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className='col-6'>
                            <TableData title={this.props.data.t.read("vendor_variance")} t={this.props.data.t} availableColumns={this.vendorsVariance} url="/dataset/vendor_variance" selection={(s) => this.setState((prev) => ({ ...prev, selected: s }))} />
                        </div>
                    </div>
                    {
                        this.state.selected && <div className="overlay" onClick={() => this.setState((prev) => ({ ...prev, selected: null }))}>
                            <div className='d-flex'>
                                <div onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                                    <TableData selection={(s) => this.setState((prev) => ({ ...prev, currentInvoice: s}))} showButton={true} close={() => this.setState((prev) => ({ ...prev, selected: null }))} className="card col m-2 min-width-1000 min-height-70 border-radius-30" title={this.state.selected.title} t={this.props.data.t} availableColumns={this.selectionColumn} url={this.state.selected.url} filters={this.state.selected.filters} pageSize={10} />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.currentInvoice &&
                        <div className="overlay" onClick={() => this.setState((prev) => ({ ...prev, currentInvoice: null }))}>
                            <div className="overlay-content">
                            <div onClick={e => { e.preventDefault(); e.stopPropagation() }}>

                                <Invoice id={this.state.currentInvoice} invoices={[]} close={() => this.setState((prev) => ({ ...prev, selected: null, currentInvoice: null }))} />
                            </div>
                            </div>
                        </div>
                    }
                </>
            }

        </div>
    }
})));
