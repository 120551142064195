import React, { useState } from 'react';

const formatMoney = (value, locale) => {
  if (!value) return '';
  
  let numberValue = value;

  if(isNaN(numberValue))
    numberValue = parseFloat(numberValue.replace(/,/g, ''));
  
    if (isNaN(numberValue)) return '';
  return `\$ ${new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(numberValue)}`;
};

const MoneyInput = ({ value, onChange, locale = 'en-US', placeholder = "" }) => {
  const [inputValue, setInputValue] = useState(formatMoney(value || '', locale));

  const handleInputChange = (e) => {
    const { value } = e.target;
    const cleanValue = value.replace(/[^0-9.]/g, '');
    setInputValue(cleanValue);
  };

  const handleBlur = () => {
    const formattedValue = formatMoney(inputValue, locale);
    setInputValue(formattedValue);
    onChange(inputValue);
  };

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      className="form-control"
    />
  );
};

export default MoneyInput;
