import { useEffect, useRef, useState } from "react";
import ApiWrapper from "../utils/api-wrapper";
import axios from "axios";
import { Link, withRouter, useParams, useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InvoicePreview from '../components/Invoice-preview';
import Expense from "../components/expense";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Modal from '../components/modal';
import Checkbox from "../components/checkbox";

const ExpensesAccountCreation = ({ data }) => {
    const history = useHistory();
    const source = axios.CancelToken.source();
    const { account_id } = useParams(); // Access the id parameter from the URL
    const [expensesAccount, setExpensesAccount] = useState();
    const [currentExpense, setCurrentExpense] = useState(null);
    const [loading, setLoading] = useState(true);
    const [savingError, setSavingError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [xpensesDeleteSelection, setXpensesDeleteSelection] = useState([]);
    const [xpensesDeleteConfirm, setXpensesDeleteConfirm] = useState(false);
    const timeoutId = useRef(null);

    const isAllXpensesSelected = () => {
        if (!expensesAccount || !expensesAccount.expenses)
            return false;
        return xpensesDeleteSelection.length > 1 && xpensesDeleteSelection.length === expensesAccount.expenses.length;
    }

    const deleteXpenses = () => {
        ApiWrapper.fetch(null, `/expenses_account/expenses/${account_id}`, "DELETE", {"checksums": xpensesDeleteSelection},
        response => {
             fetchExpensesAccount(); 
             setXpensesDeleteSelection([]);
             setXpensesDeleteConfirm(false);
        }
        , null, false, source.token);
    }
    const fetchExpensesAccount = () => {
        const searchData = `?account_id=${encodeURIComponent(account_id)}`;

        ApiWrapper.fetch(null, `/expenses_account${searchData}`, "GET", null,
            (response) => {
                setExpensesAccount(response.data.expenses_account);
                setLoading(false);
                if (response.data.expenses_account.expenses.find(x => ["Pending", "Processing"].includes(x.status)) != undefined )
                    timeoutId.current = setTimeout(
                        () => fetchExpensesAccount(), 3000
                    );
                else
                    clearInterval(timeoutId.current);
                }
            ,
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchExpensesAccount()
            }
            , false, source.token);
    }

    useEffect(() => {
        fetchExpensesAccount();
    }
        , [account_id])

    useEffect(() => {
         return () => {
            source.cancel('expenses_account_creation unmounted');
            clearInterval(timeoutId.current);
        }
    }, [])

    const closeInvoicePrompt = () => {
        fetchExpensesAccount();
        setCurrentExpense(null);
    }

    const submit = () => {
        setSaving(true)
        ApiWrapper.fetch(null, "/expense_account/submit/" + account_id, "PUT", null,
            response => {
                setSaving(false);
                history.push(`/expenses_accounts/`);
            },
            error => {
                setSaving(false);
                console.error(error);
                if (error.response.status != 500)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            })
    }

    const toggleAllXpensesSelected = () => {
        xpensesDeleteSelection.length === expensesAccount.expenses.length ? setXpensesDeleteSelection([]) : setXpensesDeleteSelection([...expensesAccount.expenses.flatMap(x => x.checksum)]);
    }
    const isXpenseSelected = (xpens) => {
        return xpensesDeleteSelection.find(x => x == xpens.checksum) != undefined;
    }
    return <div className="card p-1 mt-5 rounded-lg">
        <div className="card-body">
            <div className="card-title d-flex justify-content-center">
                <h2>{data.t.read("expenses_account")}</h2>
            </div>
            <div className="d-flex justify-content-end m-1">
                <button type="button" className="btn btn-primary btn-primary-shadow" disabled={saving | expensesAccount?.status == "Submitted"} onClick={() => submit()}>
                    <div className="action-buttons action-buttons-sm">
                        {data.t.read("submit")}
                        {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                    </div>
                </button>
            </div>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="information-panel-content"
                    id="information-panel-header"
                >
                    <h3>Information</h3>

                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Id :</label>
                        </div>
                        <div className="col-6">
                            <span>{account_id}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("statusTitle")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{expensesAccount?.status}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.currency")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{expensesAccount?.currency}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label> {data.t.read("number_of_receipts")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{expensesAccount?.expenses?.length}</span>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="finances-panel-content"
                    id="finances-panel-header"
                >
                    <h3>{data.t.read("summary")}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense {data.t.read("invoice.sub_total")} :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {expensesAccount?.Total?.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.tax")}1 :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {expensesAccount?.Tax1?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.tax")}2 :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {expensesAccount?.Tax2?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense Taxes :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {expensesAccount?.Tax?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("tips")} :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {expensesAccount?.Tip?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense {data.t.read("invoice.total")} :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {expensesAccount?.BigTotalPayed?.toFixed(2)}</span>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="xpenses-panel-content"
                    id="xpenses-panel-header"
                >
                    <h3>Xpenses</h3>
                </AccordionSummary>
                <AccordionDetails>
                        {
                        data.isMobile && <div className="text-right">
                            <button hidden={expensesAccount?.status == "Submitted"} type="button" className="mr-1 pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving | xpensesDeleteSelection.length === 0} onClick={() => setXpensesDeleteConfirm(true)}>
                                <div>
                                    {data.t.read("delete")}
                                    {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                </div>
                            </button>
                            <Link hidden={expensesAccount?.status == "Submitted"} to={`/expenses_account/${account_id}/add`} className="btn btn-sm btn-primary btn-primary-shadow pb-2 pt-2">
                                {data.t.read("add_new")}
                            </Link>
                        </div>
                        }
                    <div className="d-flex">
                        <div className="col-8">
                            <table className="table table-borderless table-sm">
                                <thead>
                                    <tr>
                                        <th><Checkbox disabled={!expensesAccount || !expensesAccount.expenses} isChecked={isAllXpensesSelected()} onChange={toggleAllXpensesSelected} /></th>
                                        {!data.isMobile && <th></th>}
                                        <th>Xpense {data.t.read(`name`)}</th>
                                        {!data.isMobile &&<th>Xpense {data.t.read(`invoiceDate`)}</th>}
                                        <th>Xpense {data.t.read(`invoice.total`)}</th></tr>
                                </thead>
                                <tbody>
                                    {expensesAccount && expensesAccount.expenses.map(
                                        xpens =>
                                            <tr key={xpens.checksum}>
                                                <td><Checkbox isChecked={isXpenseSelected(xpens)} onChange={e => isXpenseSelected(xpens) ? setXpensesDeleteSelection(xpensesDeleteSelection.filter(x => x != xpens.checksum)) : setXpensesDeleteSelection([...xpensesDeleteSelection, xpens.checksum])} /></td>
                                                {!data.isMobile && <td>
                                                    <InvoicePreview invoice_id={account_id + "_" + xpens.checksum} currentInvoice={currentExpense} />
                                                </td>}
                                                {
                                                    ["Pending", "Processing"].includes(xpens.status) == false ?
                                                        <td onClick={() => setCurrentExpense(xpens)} style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}>
                                                            {xpens.name}
                                                        </td>
                                                        :
                                                        <td>
                                                            <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                                                        </td>
                                                }
                                                {!data.isMobile &&<td>
                                                    {xpens.json?.InvoiceDate}
                                                </td>}
                                                <td>
                                                    {xpens.json && xpens.json?.BigTotalPayed && `$${xpens.json.BigTotalPayed.toFixed(2)}`}
                                                </td>
                                            </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {!data.isMobile && <div className="col-4 text-right">
                            <button hidden={expensesAccount?.status == "Submitted"} type="button" className="mr-1 pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving | xpensesDeleteSelection.length === 0} onClick={() => setXpensesDeleteConfirm(true)}>
                                <div>
                                    {data.t.read("delete")}
                                    {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                </div>
                            </button>
                            <Link hidden={expensesAccount?.status == "Submitted"} to={`/expenses_account/${account_id}/add`} className="btn btn-sm btn-primary btn-primary-shadow pb-2 pt-2">
                                {data.t.read("add_new")}
                            </Link>
                        </div>}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>

        {currentExpense && <div className="overlay" onClick={() => closeInvoicePrompt(false)}>
            <div className="overlay-content">
                <div className="invoice-container" onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                    <Expense account_id={account_id} expenses={expensesAccount.expenses} selected={currentExpense} data={data} close={() => { setCurrentExpense(null); fetchExpensesAccount(); }} />
                </div>
            </div>
        </div>}
        {
            savingError != null ?
                <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                    <div className="text-danger">
                        {savingError}
                    </div>
                </Modal>
                :
                null
        }
        {
            xpensesDeleteConfirm == true ?
                <Modal title={data.t.read("confirm")} cancelAction={() => setXpensesDeleteConfirm(false)} okAction={() => deleteXpenses()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                    {data.t.read("delete")} ?
                </Modal>
                :
                null
        }
    </div>
}
export default inject('data')(withRouter(observer(ExpensesAccountCreation)));