import ApiWrapper from "./api-wrapper";
import { saveAs } from 'file-saver';
import iconv from 'iconv-lite';
var JSZip = require("jszip");

export default class FileManager {
    /**
     * This method download an invoice in the given format.
     * @param {str} id 
     * @param {str} format 
     */
    static downloadFile(id, format) {
        let { ext, mimeType } = FileManager.formatToExtensionMime(format);

        ApiWrapper.fetch(this, "/invoices/" + id + "/" + format, "GET", {},
            response => {
                if (format === "acomba") {
                    response.data.forEach(file => {
                        if(file.file_string === "") return;
                        
                        let a = document.createElement('a');
                        a.href = 'data:attachment/csv,' + encodeURIComponent(file.file_string);
                        a.target = '_blank';
                        a.download = file.file_name + '.csv';
    
                        document.body.appendChild(a);
                        a.click();
                    });
                    return;
                }
                
                let filename = "invoice" + ext;
                let pom = document.createElement('a');

                if (format === "json") {
                    response.data = JSON.stringify(response.data);
                }
                else if (format === "pdf") {
                    response.data = iconv.encode(response.data.pdf, 'iso-8859-1');
                }

                let bb = new Blob([response.data], { type: mimeType });

                pom.setAttribute('href', window.URL.createObjectURL(bb));
                pom.setAttribute('download', filename);

                pom.dataset.downloadurl = [mimeType, pom.download, pom.href].join(':');
                pom.draggable = true;
                pom.classList.add('dragout');

                pom.click();
            },
            response => {
                // invoice not found
            })
    }

    /**
     * This method takes a format (example : json, elite_xml or pdf) and returns the extension + mimetype of that format
     * @param {str} format 
     * @returns extension + mimetype in str
     */
    static formatToExtensionMime(format) {
        var ext = "";
        var mimeType = "";

        switch (format) {
            case "elite_xml": ext = ".xml"; mimeType = "text/xml";
                break;
            case "pdf": ext = ".pdf"; mimeType = "application/pdf";
                break;
            case "acomba": ext = ".csv"; mimeType = "text/csv";
                break;
            default: ext = ".json"; mimeType = "application/json";
                break;
        }
        return { ext, mimeType };
    }

    /**
     * This method takes a list of invoice ids and the download format to download all the invoice into a zip file.
     * TODO : Only call the API 1 time (so you need to add a body parameter in ApiWrapper.fetch)
     * @param {array} ids 
     * @param {str} format 
     */
    static async downloadFiles(ids, format) {
        let { ext, mimeType } = FileManager.formatToExtensionMime(format);

        let zip = new JSZip();
        await ApiWrapper.fetch(this, "/invoices/" + format + "/", "GET", { "ids": ids },
            response => {
                if (format === "acomba") {
                    response.data.forEach(file => {
                        if(file.file_string === "") return;

                        let a = document.createElement('a');
                        a.href = 'data:attachment/csv,' + encodeURIComponent(file.file_string);
                        a.target = '_blank';
                        a.download = file.file_name + '.csv';
    
                        document.body.appendChild(a);
                        a.click();
                    });
                    return;
                }

                response.data.invoices.forEach(el => {
                    let tmp = el.invoice;
                    let id = el.id;

                    if (format === "json") {
                        tmp = JSON.stringify(el.data);
                    }
                    else if (format === "pdf") {
                        tmp = iconv.encode(el.pdf, 'iso-8859-1');
                    }
                    
                    let bb = new Blob([tmp], { type: mimeType });
                    zip.file("invoice-" + id + ext, bb);
                });

                zip.generateAsync({ type: "blob" }).then(function (content) {
                    saveAs(content, "invoices.zip");
                });
            },
            response => {
                // invoice not found
            });

        ;/*
        for (let i = 0; i < ids.length; i++) {
            await ApiWrapper.fetch(this, "/invoices/" + ids[i] + "/" + format, "GET", {},
                response => {
                    if (format === "json") {
                        response.data = JSON.stringify(response.data);
                    }
                    else if (format === "pdf") {
                        response.data = iconv.encode(response.data.pdf, 'iso-8859-1');
                    }

                    let bb = new Blob([response.data], { type: mimeType });
                    zip.file("invoice-" + ids[i] + ext, bb);
                },
                response => {
                    // invoice not found
                });
        }

        */
    }
}