import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ApiWrapper from "../utils/api-wrapper";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../components/modal";
import { faSpinner, faSync } from "@fortawesome/free-solid-svg-icons";
import availableColumns from "../utils/available-columns";

const PaiementsApproval = ({ data }) => {

    const source = axios.CancelToken.source();

    const [batches, setBatches] = useState([]);
    const [saving, setSaving] = useState({});
    const [loading, setLoading] = useState(true);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState([]);
    const [globalfilter, setGlobalfilter] = useState("");
    const [dirtyGlobalfilter, setDirtyGlobalfilter] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [openedBatch, setOpenedBatch] = useState(null);
    const [denyReason, setDenyReason] = useState({});

    const isApproved = (paiement) => {
        return paiement.status === "Approved";
    }

    const handleCheckboxChange = (checked, batch, paiement) => {
        const shallow = [...batches];

        if (checked)
            paiement.status = "Approved";
        else
            paiement.status = "Denied";

        const paiementIndex = batch.payments.findIndex(p => p.supplier_id === paiement.supplier.supplier_id);
        batch.payments[paiementIndex] = paiement;

        const batchIndex = shallow.findIndex(b => b._id.$oid === batch._id.$oid);
        shallow[batchIndex] = batch;

        setBatches(shallow);
    }

    const sum = (accumulator, currentValue) => {
        // Convert currentValue to a number using parseFloat
        const numberValue = parseFloat(currentValue);
        // Check if numberValue is a valid number
        if (!isNaN(numberValue)) {
            // Add the numberValue to the accumulator
            accumulator += numberValue;
        }
        return accumulator;
    };

    const save = (batch) => {
        setSaving(s => ({ ...s, [batch._id.$oid]: true }));
        let formData = new FormData();
        formData.append("batch", JSON.stringify(batch));

        ApiWrapper.fetch(null, `/payments/batch/`, "PUT", null, (response) => {
            setSaving(s => ({ ...s, [batch._id.$oid]: false }));
        },
            null, false, source.token)
    }

    const total_with_tax = (selected) => selected.payments.filter(p => p.status === "Approved").map(p => p.TotalWithTax).reduce(sum, 0);
    const total = (selected) => selected.payments.filter(p => p.status === "Approved").map(p => p.Total).reduce(sum, 0);

    const total_batch = (bcs) => bcs.payments.map(p => p.Total).reduce(sum, 0);
    const total_with_tax_batch = (bcs) => bcs.payments.map(p => p.TotalWithTax).reduce(sum, 0);

    const refresh = () => {
        let start = page * pageSize;

        if (globalfilter.length !== 0 || filters.length !== 0)
            start = 0;

        const searchData = `REQUEST_APPROVAL?start=${encodeURIComponent(start)}&page_size=${encodeURIComponent(pageSize)}&globalfilter=${encodeURIComponent(globalfilter ?? '')}&filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        ApiWrapper.fetch(null, `/payments/invoices/${searchData}`, "GET", null, (response) => {
            setBatches(response.data.batches);
            setLoading(false);
            setTotalRowCount(response.data.meta.totalRowCount);
        },
            null, false, source.token);
    }

    useEffect(() => {
        refresh();
    }, []);

    const approvePaiement = (p, batch_id) => {
        setSaving(s => ({ ...s, [`${p.supplier.supplier_id}-approve`]: true, [p.supplier.supplier_id]: true }));

        let formData = new FormData();
        formData.append("payment", JSON.stringify(p));
        formData.append("status", "Approved");
        formData.append("batch_id", batch_id);

        ApiWrapper.fetch(null, `/payments/`, "PUT", formData, (response) => {
            setSaving(s => ({ ...s, [`${batch_id}-approve`]: false, [batch_id]: false }));
            setOpenedBatch(null);
            refresh();
        },
            null, false, source.token);

    }

    const disApprovePaiement = (p, batch_id) => {
        setSaving(s => ({ ...s, [`${p.supplier.supplier_id}-deny`]: true, [p.supplier.supplier_id]: true }));

        let formData = new FormData();
        formData.append("payment", JSON.stringify(p));
        formData.append("status", "Denied");
        formData.append("batch_id", batch_id);

        ApiWrapper.fetch(null, `/payments/`, "PUT", formData, (response) => {
            setSaving(s => ({ ...s, [`${batch_id}-deny`]: false, [batch_id]: false }));
            setOpenedBatch(null);
            refresh();
        },
            null, false, source.token);
    }

    const approveBatch = (b) => {
        setSaving(s => ({ ...s, [`${b._id.$oid}-approve`]: true, [b._id.$oid]: true }));

        let formData = new FormData();
        formData.append("batch", JSON.stringify(b));
        formData.append("status", "Approved");

        ApiWrapper.fetch(null, `/payments/batch/`, "PUT", formData, (response) => {
            setSaving(s => ({ ...s, [`${b._id.$oid}-approve`]: false, [b._id.$oid]: false }));
            setOpenedBatch(null);
            refresh();
        },
            null, false, source.token);
    }

    const disApproveBatch = () => {
        setSaving(s => ({ ...s, [`${denyReason.batch._id.$oid}-deny`]: true, [denyReason.batch._id.$oid]: true }));
        let formData = new FormData();
        formData.append("batch", JSON.stringify(denyReason.batch));
        formData.append("status", "Denied");
        formData.append("reason", denyReason.reason);

        ApiWrapper.fetch(null, `/payments/batch/`, "PUT", formData, (response) => {
            setSaving(s => ({ ...s, [`${denyReason.batch._id.$oid}-deny`]: false, [denyReason.batch._id.$oid]: false }));
            setDenyReason({});
            setOpenedBatch(null);
            refresh();
        },
            null, false, source.token);
    }

    return loading ?
        <>
            {errorMessage ?
                <Modal title={data.t.read("error")} okAction={() => { setErrorMessage(null); setLoading(false) }} okText={"ok"}>
                    <div className="text-danger">
                        {errorMessage}
                    </div>
                </Modal>
                :
                <div className="loading-container">
                    <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
                </div>
            }
        </>
        :
        <div className="card p-1 mt-5 rounded-lg">
            <div className="card-body">
                <div className="card-title">
                    <h2>{data.t.read("batch_list_to_approve")}</h2>
                </div>
                <ul className="list-group">
                    {
                        batches.map(batch =>
                            <div key={`${batch._id.$oid}-div-list`} className="card" onClick={() => setOpenedBatch(batch)}>
                                <li key={`${batch._id.$oid}-li`} className="p-1 pointer list-group-item d-flex justify-content-between">
                                    <div className="col-md-3">
                                        {data.t.read("send_for_payment_by")}
                                        <span className="font-italic"> {batch.requester.firstname} {batch.requester.lastname}</span>
                                    </div>
                                    <div className="ml-3 col-3">
                                        {new Date((batch.date_request.$date)).toLocaleString(`${data.t.lang}-US`, { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true })}
                                    </div>
                                    <div className="ml-3 col-2 text-right">
                                        {total_batch(batch).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" })}
                                    </div>
                                    <div className="ml-1 col-2 text-right">
                                        {total_with_tax_batch(batch).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" })}
                                    </div>
                                </li>
                            </div>
                        )
                    }
                </ul>
                {
                    openedBatch &&
                    <div className="card overlay" onClick={() => setOpenedBatch(null)}>
                        <div className="overlay-content" >
                            <div className="card m-1 p-1 rounded-lg" onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                                <div className="card-body m-5 p-0">
                                    <div className="d-flex mb-4 ml-2 justify-content-between">
                                        <div>
                                            <div>
                                                <button className="btn btn-sm mr-2 pointer btn-primary" disabled={saving[openedBatch._id.$oid]} onClick={() => approveBatch(openedBatch)}>
                                                    {data.t.read("approve")}
                                                    {saving[openedBatch._id.$oid + "-approve"] && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                                </button>
                                                <button className="btn btn-sm pointer mr-3 btn-danger" disabled={saving[openedBatch._id.$oid]} onClick={() => setDenyReason({ showDenyReason: true, batch: openedBatch })}>
                                                    {data.t.read("disapprove")}
                                                    {saving[openedBatch._id.$oid + "-deny"] && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                                </button>
                                                <span className="font-weight-bold mr-3">{data.t.read("invoice.created_at")}</span>
                                                <span className="font-italic">{new Date((openedBatch.date_request.$date)).toLocaleString(`${data.t.lang}-US`, { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true })}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="mr-3 col-3">
                                                {data.t.read("send_for_payment_by")}
                                                <span className="font-italic"> {openedBatch.requester.firstname} {openedBatch.requester.lastname}</span>
                                            </span>
                                        </div>
                                        <div>
                                            <span className="mr-3">
                                                {total_batch(openedBatch).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" })}
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                {total_with_tax_batch(openedBatch).toLocaleString(`${data.t.lang}-CA`, { style: 'currency', currency: "CAD" })}
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        openedBatch.payments.map(paiement =>
                                            <div key={`${openedBatch._id.$oid}-${paiement.supplier.supplier_id}-card`} className="table m-1 p-0">
                                                <div key={`${openedBatch._id.$oid}-${paiement.supplier.supplier_id}-header`} className="card-header d-flex p-0">
                                                    {paiement.status === "REQUEST_APPROVAL" ?
                                                    <div key={`${openedBatch._id.$oid}-${paiement.supplier.supplier_id}-btn-div`}>
                                                        <button className="btn btn-sm m-1  btn-primary" disabled={saving[paiement.supplier.supplier_id + "-approve"] || saving[paiement.supplier.supplier_id + "-deny"] || saving[openedBatch._id.$oid]} onClick={() => approvePaiement(paiement, openedBatch._id.$oid)}>
                                                            {data.t.read("approve")}
                                                            {saving[paiement.supplier.supplier_id + "-approve"] && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                                        </button>
                                                        <button key={`${openedBatch._id.$oid}-${paiement.supplier.supplier_id}-disapprove`} className="btn btn-sm m-1  btn-danger" disabled={saving[paiement.supplier.supplier_id + "-approve"] || saving[paiement.supplier.supplier_id + "-deny"] || saving[openedBatch._id.$oid]} onClick={() => setDenyReason({ showDenyReason: true, paiement: paiement })} >
                                                            {data.t.read("disapprove")}
                                                            {saving[paiement.supplier.supplier_id + "-deny"] && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                                        </button>
                                                    </div> : 
                                                    <button disabled="true" className={paiement.status === "Denied"? "btn btn-sm m-1  btn-danger":"btn btn-sm m-1  btn-primary"}>
                                                        {paiement.status}
                                                    </button>
                                                    }

                                                    <div className="p-2">
                                                        {paiement.supplier.names[0]}
                                                    </div>
                                                </div>
                                                <table key={`${openedBatch._id.$oid}-${paiement.supplier.supplier_id}-table`} className="table table-hover m-0 p-0">
                                                    <thead>
                                                        <tr>
                                                            {availableColumns.map((availableColumn) => (
                                                                <th key={`header-${availableColumn.displayName}}`}>{data.t.read(`invoice.${availableColumn.displayName}`)}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paiement.invoices.map(inv => <tr key={`${inv.invoice_id}-list-tr`}>
                                                            {availableColumns.map(availableColumn => <td key={`${inv.invoice_id}-${availableColumn.displayName}-value`}>{availableColumn.evaluate(inv)}</td>)}
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                    {denyReason.showDenyReason ?
                                        <Modal overflow="visible" title={data.t.read("denial_cause_placeholder")} cancelAction={() => setDenyReason({})} okAction={() => denyReason.batch ? disApproveBatch() : disApprovePaiement(denyReason.paiement, openedBatch._id.$oid)} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>
                                            <input className='form-control' type="text" onChange={e => setDenyReason((s => ({ ...s, reason: e.target.value })))} />
                                        </Modal>
                                        :
                                        null}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
}

export default inject('data')(withRouter(observer(PaiementsApproval)));