import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import {withRouter} from "react-router-dom";
import FormSelect from '../components/form-select';

export default inject("data")(withRouter(observer(class Data extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            invoices : [
                {
                    "username" : "JB",
                    "supplier_name" : 1233,
                    "invoice_number" : 1.56897,
                    "created_at" : "06/07/2021",
                    "currency" : "CAD",
                    "amount" : 1200.00,
                    "process_time" : 0.02,
                    "status" : "Finalized"
                }
            ]
        }
    }

    render() {
        return  <div className="data-page">
                    <div className="d-flex justify-content-between mb-4 pl-2">
                        <div className="d-flex flex-3">
                            <FormSelect title={this.props.data.t.read("user")} options={[]} optionChanged={option => {} } />
                        </div>
                        <div className="flex-2 d-flex justify-content-end">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-sm btn-light">1{this.props.data.t.read("D")}</button>
                                <button type="button" className="btn btn-sm btn-light">1M</button>
                                <button type="button" className="btn btn-sm btn-light">1{this.props.data.t.read("Q")}</button>
                                <button type="button" className="btn btn-sm btn-primary">1{this.props.data.t.read("Y")}</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table className="table table-sm ">
                            <thead>
                                <tr>
                                    <th>{this.props.data.t.read("username")}</th>
                                    <th>{this.props.data.t.read("supplierName")}</th>
                                    <th>{this.props.data.t.read("invoiceNumber")}</th>
                                    <th>{this.props.data.t.read("invoiceDate")}</th>
                                    <th>{this.props.data.t.read("currency")}</th>
                                    <th>{this.props.data.t.read("invoiceTotal")}</th>
                                    <th>{this.props.data.t.read("processTime")}</th>
                                    <th>{this.props.data.t.read("statusTitle")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.invoices.map(invoice => {
                                        return <tr key={invoice.invoice_number}>
                                            <td>{invoice.username}</td>
                                            <td>{invoice.supplier_name}</td>
                                            <td>{invoice.invoice_number}</td>
                                            <td>{invoice.created_at}</td>
                                            <td>{invoice.currency}</td>
                                            <td>{invoice.amount}</td>
                                            <td>{invoice.process_time}</td>
                                            <td>{invoice.status}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
    }
})));