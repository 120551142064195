import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

const SearchableDropdown = ({
  options,
  label,
  id,
  selectedVal,
  handleChange
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    selectedVal = option;
    setQuery(getDisplayLabel(option));
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal && !isOpen) return getDisplayLabel(selectedVal);
    return "";
  };

  const getDisplayLabel = (option) => {
    return label.map(lab => option[lab]).join(" ");
  }

  const filter = (options) => {
    return options.filter(
      (option) => getDisplayLabel(option).toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div className="position-relative">
      <div className="d-flex form-select-box">
        
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
          />
        
        <div onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon className="text-primary" icon={isOpen ? faCaretUp: faCaretDown}  />
        </div>
      </div>

      <div className="position-absolute form-select-option">
        {isOpen && filter(options).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              key={`${id}-${index}`}
            >
              {getDisplayLabel(option)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
