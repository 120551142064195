import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'; 
import ApiWrapper from '../utils/api-wrapper';
import axios from "axios";
import Switch from "react-switch";

const XLSX = require('xlsx');

const Reports = (props) => {
    const [dateMin, setDateMin]= useState("");
    const [dateMax, setDateMax]= useState("");
    const [dateCreaMin, setDateCreaMin]= useState("");
    const [dateCreaMax, setDateCreaMax]= useState("");
    const [dateUpdMin, setDateUpdMin]= useState("");
    const [dateUpdMax, setDateUpdMax]= useState("");
    const [status, setStatus] = useState([]);
    const [saving, setSaving] = useState(false);
    const [unit, setUnit] = useState([]);
    const [unitList, setUnitList] = useState(null);
    const [glNaturals, setGlNaturals] = useState(null);
    const [glNaturalsSelected, setGlNaturalsSelected] = useState([]);
    const [users, setUSers] = useState(null);
    const [usernames, setUsernames] = useState([]);
    const [approvers, setApprovers] = useState(null);
    const [selectedApprovers, setSelectedApprovers] = useState([]);
    const [deleted, setDeleted] = useState(false);
    const [suppliersNo, setSuppliersNo] = useState([]);
    const [suppliers, setSuppliers] = useState(null);
    const [approverUsedSuppliers, setApproverUsedSuppliers] = useState([]);

    const [format, setFormat] = useState({extension: ".xlsx", type:"application/vnd.ms-excel;charset=utf-8", delimiter:'\t'});
    const styleSelect = {
        control: (baseStyles, state) => ({
        ...baseStyles,
        border: "0 !important",
        boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important"
        })
    };

    //waiting for a client to purchase
    /* useEffect(() => {
        if(selectedApprovers.length > 0 ) {
            
            const searchData = "?approvers=" + encodeURIComponent(selectedApprovers.map(u => u.value).join(' ')) + "&";
            ApiWrapper.fetch(null, `/suppliers/approver/${searchData}`, "GET", {},
            response =>  setApproverUsedSuppliers(response.data),
            null, false, source.token );
        }
        else 
            setApproverUsedSuppliers([]);
    }, [selectedApprovers]); */

    useEffect(() => {
        ApiWrapper.fetch(null, "/suppliers/units", "GET", {},
            response =>  setUnitList(response.data),
            null, false, source.token );
        
            //wainting for the client to purchase
        /* ApiWrapper.fetch(null, "/gl/nat/list/", "GET", {},
            response =>  setGlNaturals(response.data),
            null, false, source.token ); */

        if(props.data.role && props.data.role.includes('Admin')){
        ApiWrapper.fetch(null, "/client/usersDetail", "GET", {},
                response => setUSers(response.data),
                null, false, source.token);
        }

        //waiting for purchase
        /* if(props.data.role && props.data.role.includes('Admin')){
            ApiWrapper.fetch(null, "/client/users/Approver", "GET", {},
                    response => setApprovers(response.data),
                    null, false, source.token);
        } */

        ApiWrapper.fetch(null, "/suppliers/list", "GET", {},
        response =>  setSuppliers(response.data),
        null, false, source.token );
        
            
    },[props]);
    
    const availableColumns = [
        { "name": "created_at", "evaluate": inv => (new Date(inv.created_at)).toLocaleString(), "displayName": "created_at" },
        { "name": "updated_at", "evaluate": inv => (new Date(inv.updated_at)).toLocaleString(), "displayName": "updated_at" },
        { "name": "json.InvoiceDate", "evaluate": inv => inv.json ? inv.json.InvoiceDate : "", "displayName": "billing_date" },
        { "name": "json.DueDate", "evaluate": inv => inv.json && inv.json.DueDate ? inv.json.DueDate : "", "displayName": "due_date" },
        { "name": "json.ListSupplier[0].Number", "evaluate": inv => inv.json && inv.json.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Number : "", "displayName": "supplier_number" },
        { "name": "json.ListSupplier[0].Name", "evaluate": inv => inv.json && inv.json.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Name : "", "displayName": "supplier_name" },
        { "name": "json.InvoiceNo", "evaluate": inv => inv.json ? inv.json.InvoiceNo : "", "displayName": "invoice_number" },
        { "name": "json.Currency", "evaluate": inv => inv.json ? inv.json.Currency : "", "displayName": "currency" },
        { "name": "status", "evaluate": inv => inv.status, "displayName": "status" },
        { "name": "json.Total", "evaluate": inv => inv.json && inv.json.Total ? "$" + inv.json.Total.toFixed(2) : "", "displayName": "total" },
        { "name": "json.TotalWithTax", "evaluate": inv => inv.json && inv.json.TotalWithTax ? "$" + inv.json.TotalWithTax : "", "displayName": "total_with_tax" },
        { "name": "json.GLUnit", "evaluate": inv => inv.json && inv.json.GLUnit ? inv.json.GLUnit : "", "displayName": "unit" },
        { "name": "approver", "evaluate": inv => inv.json && inv.json.approver ? inv.json.approver.first_name + " " + inv.json.approver.last_name  : "", "displayName": "approver" },
        { "name": "status_details", "evaluate": inv => inv.status_details, "displayName": "comments" }
    ];

    const convertToCSV = (invoices) => {
        const data = [availableColumns.map(column => sanitizeValue(props.data.t.read("invoice."+column.displayName))).join(format.delimiter)];
        invoices.map(
            invoice => data.push(availableColumns.map(column => sanitizeValue(columnValue(invoice, column))).join(format.delimiter))
            );
        return new Blob([data.join("\n")], { type: format.type});
    }

    const convertToXLSX = (invoices) => {
        const jsonData = [];
        for(const invoice of invoices){
            const item = {};
            availableColumns.map(column => 
                item[sanitizeValue(props.data.t.read("invoice."+column.displayName))] = sanitizeValue(columnValue(invoice, column))
            );
            jsonData.push(item);
        }

        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // You can name the sheet as you like

        // Generate the XLSX data
        const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Create a Blob from the XLSX data
        const blob = new Blob([xlsxData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return blob;
    }

    const columnValue = (invoice, column) => {
        try {
            return column.evaluate(invoice);
        }
        catch (e) {
        }

        return "";
    }

    const download = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename + format.extension;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    const sanitizeValue = (value) => {
        if(value !== null && value !==undefined) {
            if (value.includes('"')) {
                value = value.replace(/"/g, '""');
              }

            if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                value = `"${value}"`;
            }
        }
        return value;
    }

    const source =  axios.CancelToken.source();
    const exportReport = () => {
    let searchData = "?";

    if(dateMin !== "" )
        searchData += "date-min=" + encodeURIComponent(dateMin) + "&";

    if(dateMax !== "" )
        searchData += "date-max=" + encodeURIComponent(dateMax) + "&";

    if(dateUpdMin !== "" )
        searchData += "date-upd-min=" + encodeURIComponent(dateUpdMin) + "&";

    if(dateUpdMax !== "" )
        searchData += "date-upd-max=" + encodeURIComponent(dateUpdMax) + "&";

    if(dateCreaMin !== "" )
        searchData += "date-crea-min=" + encodeURIComponent(dateCreaMin) + "&";

    if(dateCreaMax !== "" )
        searchData += "date-crea-max=" + encodeURIComponent(dateCreaMax) + "&";

    if(status.length > 0 )
        searchData += "status=" + encodeURIComponent(status.map(st => st.value).join(' ')) + "&";

    if(usernames.length > 0 )
        searchData += "usernames=" + encodeURIComponent(usernames.map(u => u.value).join(' ')) + "&";

    if(selectedApprovers.length > 0 )
        searchData += "approvers=" + encodeURIComponent(selectedApprovers.map(u => u.value).join(' ')) + "&";

    if(suppliersNo.length > 0 )
        searchData += "supplier_numbers=" + encodeURIComponent(suppliersNo.map(s => s.value).join(' ')) + "&";

    if(deleted !== false )
        searchData += "is-deleted=" + encodeURIComponent(deleted) + "&";

    if(unit.length > 0 )
        searchData += "unit=" + encodeURIComponent(unit.map(u => u.value).join(' ')) + "&";

    if(glNaturalsSelected.length > 0 )
        searchData += "gl_natural=" + encodeURIComponent(glNaturalsSelected.map(u => u.value).join(' ')) + "&";

    
    setSaving(true);
    ApiWrapper.fetch(null, `/invoices/report/${searchData}`, "GET", {},
                response => {
                    const allInvoices = response.data.invoices.sort(function (a, b) { return (a.created_at < b.created_at ? 1 : -1); });
                    const blob = format.type === 'text/csv' ? convertToCSV(allInvoices): convertToXLSX(allInvoices);
                    download(blob, 'report');
                    setSaving(false);
                },
                error => {
                    setSaving(false);
                }, false, source.token);
    }

    return <div className="container">
                <div className="d-flex">
                    <h3 className="font-weight-bold">{props.data.t.read("reports")}</h3>
                </div>

                {
                    users ?
                    <div className='d-flex'>
                        <div className='report-label col-2'>{props.data.t.read("username")} </div>
                            <div className='col-6'>
                                <Select
                                    isMulti
                                    name="user"
                                    options={users.map( u => ({value: u.username, label: u.first_name +" "+ u.last_name } ) ) }
                                    styles={styleSelect}
                                    classNamePrefix="select"
                                    onChange={(choice) => setUsernames(choice)}
                                />
                        </div>
                    </div>
                    :
                    null
                }
                {
                    approvers ?
                    <div className='d-flex'>
                        <div className='report-label col-2'>{props.data.t.read("invoice.approver")} </div>
                            <div className='col-6'>
                                <Select
                                    isMulti
                                    name="approver"
                                    options={approvers.map( u => ({value: u.username, label: u.first_name +" "+ u.last_name } ) ) }
                                    styles={styleSelect}
                                    classNamePrefix="select"
                                    onChange={(choice) => setSelectedApprovers(choice)}
                                />
                        </div>
                    </div>
                    :
                    null
                }
                <div className='d-flex'>
                    <div className='report-label col-2'>{props.data.t.read("invoice.created_at")}</div> 
                    <div className='col-3'>
                        <input min="0001-01-01" max="9999-12-31" type="date" className="form-control" value={dateCreaMin} onChange={e => setDateCreaMin(e.target.value)} />
                    </div>
                    <div className='col-3' >
                        <input type="date" min="0001-01-01" max="9999-12-31" className="form-control" value={dateCreaMax} onChange={e => setDateCreaMax(e.target.value)} />
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='report-label col-2'>{props.data.t.read("invoice.billing_date")}</div> 
                    <div className='col-3'>
                        <input type="date" min="0001-01-01" max="9999-12-31" className="form-control" value={dateMin} onChange={e => setDateMin(e.target.value)} />
                    </div>
                    <div className='col-3' >
                        <input type="date" min="0001-01-01" max="9999-12-31" className="form-control" value={dateMax} onChange={e => setDateMax(e.target.value)} />
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='report-label col-2'>{props.data.t.read("invoice.updated_at")}</div> 
                    <div className='col-3'>
                        <input type="date" min="0001-01-01" max="9999-12-31" className="form-control" value={dateUpdMin} onChange={e => setDateUpdMin(e.target.value)} />
                    </div>
                    <div className='col-3' >
                        <input type="date" min="0001-01-01" max="9999-12-31" className="form-control" value={dateUpdMax} onChange={e => setDateUpdMax(e.target.value)} />
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='report-label col-2'>{props.data.t.read("statusTitle")} </div>
                    {
                    props.data.config ?
                        <div className='col-6'>
                            <Select
                                isMulti
                                name="status"
                                options={props.data.config.invoice_status.map( st => ({value: st, label: props.data.t.read("status."+st.toLowerCase()) } ) ) }
                                styles={styleSelect}
                                classNamePrefix="select"
                                onChange={(choice) => setStatus(choice)}
                            />
                        </div>
                    :
                    <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                    }
                </div>
                <div className='d-flex'>
                    <div className='report-label col-2'>{props.data.t.read("invoice.supplier_name")} </div>
                    {
                        suppliers ?
                        <div className='col-6'>
                            <Select
                                    isMulti
                                    name="supplier"
                                    options={suppliers.filter(s => approverUsedSuppliers.length == 0 || approverUsedSuppliers.includes(s.number) ).map( s => ({value: s.number, label:s.name } ) ) }
                                    styles={styleSelect}
                                    classNamePrefix="select"
                                    onChange={(choice) => setSuppliersNo(choice)}
                                />
                    </div>
                    :
                    <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                }
                </div>
                
                <div className='d-flex'>
                    <div className='report-label col-2'>{props.data.t.read("unit")} </div>
                    {
                        unitList ?
                        <div className='col-6'>
                            <Select
                                isMulti
                                name="unit"
                                options={unitList.map( u => ({value: u.desc, label: u.desc } ) ) }
                                styles={styleSelect}
                                classNamePrefix="select"
                                onChange={(choice) => setUnit(choice)}
                            />
                    </div>
                    :
                    <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                }
                </div>
                {/* <div className='d-flex'>
                    <div className='report-label col-2'>{props.data.t.read("gl_natural")} </div>
                    {
                        glNaturals ?
                        <div className='col-6'>
                            <Select
                                isMulti
                                name="glNatural"
                                options={glNaturals.map( u => ({value: u.GLNatural, label: u.GLNatural } ) ) }
                                styles={styleSelect}
                                classNamePrefix="select"
                                onChange={(choice) => setGlNaturalsSelected(choice)}
                            />
                    </div>
                    :
                    <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                }
                </div> */}
                <div className='d-flex'>
                        <div className='report-label col-2'>Format</div> 
                        <div className='col-3'>
                            <Select
                                defaultValue={{value: {extension: ".xlsx", type:"application/vnd.ms-excel;charset=utf-8", delimiter:'\t'}, label: "Excel"}}
                                name="format"
                                options={[{value: {extension: ".xlsx", type:"application/vnd.ms-excel;charset=utf-8", delimiter:'\t'}, label: "Excel"}, {value: {extension:'.csv', type:"text/csv", delimiter:','}, label:"CSV"}]}
                                styles={styleSelect}
                                classNamePrefix="select"
                                onChange={(choice) => setFormat(choice.value)}
                            />
                        </div>
                </div>
                <div className='d-flex'>
                        <div className='report-label col-2'>{props.data.t.read("deleted_include")}</div> 
                            <div className='col-3'>
                            <Switch className='switch'
                                checked={deleted}
                                width={70}
                                onColor="#F39800"
                                onHandleColor="#2693e6"                                
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                onChange={(deleted) => setDeleted(deleted)}
                            />
                        </div>
                </div>
                <div className='d-flex' style={{justifyContent: 'center', marginTop: '50px'}}>
                    <div className="col-6">
                        <button onClick={()=>exportReport()} disabled={saving} className="btn col-6 btn-primary">{props.data.t.read("export")}
                        {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                        </button>
                    </div>
                </div>
                
                
            </div>
};

export default inject('data')(withRouter(observer(Reports)));