import { useEffect, useState } from "react";
import ApiWrapper from "../utils/api-wrapper";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEllipsisH, faSpinner, faPencilAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Modal from '../components/modal';
import Checkbox from "../components/checkbox";

const ExpensesAccount = ({ data }) => {
    const history = useHistory();
    const source = axios.CancelToken.source();
    const [expensesAccounts, setExpensesAccounts] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState([]);
    const [globalfilter, setGlobalfilter] = useState("");
    const [xpensesDeleteSelection, setXpensesDeleteSelection] = useState([]);
    const [xpensesDeleteConfirm, setXpensesDeleteConfirm] = useState(false);

    const toggleAllXpensesSelected = () => {
        xpensesDeleteSelection.length === expensesAccounts.length ? setXpensesDeleteSelection([]) : setXpensesDeleteSelection([...expensesAccounts.flatMap(x => x._id.$oid)]);
    }
    const isXpenseSelected = (xpens) => {
        return xpensesDeleteSelection.find(x => x == xpens._id.$oid) != undefined;
    }

    const allColums = [
        { "name": "id", "evaluate": inv => inv._id ? inv._id.$oid : "", "displayName": "identifiant" },
        { "name": "created_at", "evaluate": inv => (new Date(inv.created_at.$date)).toLocaleString(), "displayName": "created_at" },
        { "name": "updated_at", "evaluate": inv => (new Date(inv.updated_at.$date)).toLocaleString(), "displayName": "updated_at" },
        { "name": "submitted_at", "evaluate": inv => inv.submitted_at ? (new Date(inv.submitted_at.$date)).toLocaleString() : "", "displayName": "submitted_at" },
        { "name": "currency", "evaluate": inv => inv.currency ? inv.currency : "", "displayName": "currency" },
        { "name": "status", "evaluate": inv => inv.status, "displayName": "status" },
        { "name": "total", "evaluate": inv => inv.Total ? `$${inv.Total.toFixed(2)}` : "", "displayName": "total" },
        { "name": "totalWithTax", "evaluate": inv => inv.TotalWithTax ? `$${inv.TotalWithTax.toFixed(2)}` : "", "displayName": "total_with_tax" },
        { "name": "approver", "evaluate": inv => inv.approver ? `${inv.json.approver.first_name} ${inv.json.approver.last_name}` : "", "displayName": "approver" },
    ];
    const [availableColumns, setAvailableColumns] = useState(allColums);

    useEffect(() => { 

        if (data.isMobile) 
            setAvailableColumns(availableColumns.filter(col => ["created_at","total"].includes(col.name)));
        else
            setAvailableColumns(allColums);
            
    }, [data.isMobile])

    const isAllXpensesSelected = () => {
        if (!expensesAccounts || !expensesAccounts.length)
            return false;
        return xpensesDeleteSelection.length > 1 && xpensesDeleteSelection.length === expensesAccounts.length;
    }

    const deleteXpenses = () => {
        setSaving(true);
        ApiWrapper.fetch(null, `/expenses_account`, "DELETE", { "account_ids": xpensesDeleteSelection },
            response => {
                fetchExpensesAccounts();
                setXpensesDeleteSelection([]);
                setXpensesDeleteConfirm(false);
                setSaving(false);
            }
            , () => {setSaving(false); setXpensesDeleteConfirm(false);}, false, source.token);
    }

    const fetchExpensesAccounts = () => {
        let start = page * pageSize;

        if (globalfilter.length !== 0 || filters.length !== 0)
            start = 0;

        const searchData = `?start=${encodeURIComponent(start)}&page_size=${encodeURIComponent(pageSize)}&globalfilter=${encodeURIComponent(globalfilter ?? '')}&filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        ApiWrapper.fetch(null, `/expenses_accounts/${searchData}`, "GET", null, (response) => {
            setExpensesAccounts(response.data.expenses_accounts);
            setLoading(false);
            setTotalRowCount(response.data.meta.totalRowCount);
        },
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchExpensesAccounts()
            }
            , false, source.token);
    }

    useEffect(() => {
        fetchExpensesAccounts();
    }
        , [])

    useEffect(() => {
        return () => {
            source.cancel('expenses_accounts list unmounted');
        }
    }, [])

    const createXpensesAccount = () => {
        setLoading(true);
        ApiWrapper.fetch(null, `/expenses_account/`, "POST", null, (response) => {
            openExpensesAccount(response.data.expenses_account_id);
            setLoading(false);
        },
            null, false, source.token)
    }

    const openExpensesAccount = (id) => {
        history.push(`/expenses_account/${id}`);
    }

    return !loading ? <div className="card p-1 mt-5 rounded-lg">
        <div className="card-body">
            <div className="card-title d-flex justify-content-center">
                <h2>{data.t.read("expenses_account_list")}</h2>
            </div>
            <div className="d-flex justify-content-end m-1">
                <button type="button" className="mr-1 text-center pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving || xpensesDeleteSelection.length === 0} onClick={() => setXpensesDeleteConfirm(true)}>
                        {data.t.read("delete")}
                        {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                </button>
                <button onClick={() => createXpensesAccount()} type="button" className="btn btn-primary btn-primary-shadow">
                    {data.t.read("add_new")}
                </button>
            </div>
            <div className="table m-0 p-0">
                <table className="table table-hover m-0 p-0 table-borderless ">
                    <thead>
                        <tr>
                            <th><Checkbox disabled={!expensesAccounts || !expensesAccounts.length} isChecked={isAllXpensesSelected()} onChange={toggleAllXpensesSelected} /></th>
                            <th><div></div></th>
                            {availableColumns.map((availableColumn) => (
                                <th key={`header-${availableColumn.displayName}}`}>{data.t.read(`invoice.${availableColumn.displayName}`)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {expensesAccounts.map(inv => <tr key={`${inv._id.$oid}-list`}>
                            <td><Checkbox isChecked={isXpenseSelected(inv)} onChange={e => isXpenseSelected(inv) ? setXpensesDeleteSelection(xpensesDeleteSelection.filter(x => x != inv._id.$oid)) : setXpensesDeleteSelection([...xpensesDeleteSelection, inv._id.$oid])} /></td>
                            <td onClick={() => openExpensesAccount(inv._id.$oid)}>
                                {inv.status === "Pending" && <FontAwesomeIcon icon={faPencilAlt} />}
                                {inv.status === "Submitted" && <FontAwesomeIcon icon={faEllipsisH} style={{ color: 'blue' }} />}
                                {inv.status === "Denied" && <FontAwesomeIcon icon={faWindowClose} style={{ color: 'red' }} />}
                                {inv.status === "Approved" && <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />}
                            </td>
                            {availableColumns.map(availableColumn => <td onClick={() => openExpensesAccount(inv._id.$oid)} key={`${inv._id.$oid}-${availableColumn.displayName}-value`}>{availableColumn.evaluate(inv)}</td>)}
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        {
            xpensesDeleteConfirm == true ?
                <Modal title={data.t.read("confirm")} cancelAction={() => setXpensesDeleteConfirm(false)} okAction={() => deleteXpenses()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                    {data.t.read("delete")} ?
                </Modal>
                :
                null
        }
    </div>
        :
        <div className="d-flex loading-container">
            <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
        </div>
}

export default inject('data')(withRouter(observer(ExpensesAccount)));