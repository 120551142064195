import axios from 'axios';

export default class ApiWrapper {

	static async fetch(component, url, method, params, callback, errorCallback = null, isFileUploadForm = false, cancelToken = null) {
		let headers = {};

		if (params == null) {
			params = {}
		}
		if (window.localStorage.getItem("jwt") != null) {
			headers["common"] = {
				Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
			}
		}

		if (isFileUploadForm) {
			headers['Content-Type'] = 'multipart/form-data';
		}

		if (method === "DELETE") {
			return axios({
				headers: headers,
				method: method,
				params: params,
				url: process.env.REACT_APP_API_URL + url,
				cancelToken: cancelToken
			})
				.then(data => {
					if (data.data === "INTERNAL_ERROR") {
						throw new Error(data);
					}
					else if (callback != null) {
						callback(data);
					}
				})
				.catch(e => {
					if (errorCallback != null) {
						errorCallback(e);
					}
					else {
						if (e.message.indexOf("Request aborted") === -1) {
							window.localStorage.removeItem("jwt");
							if(component != null){
								component.props.data.username = "";
								component.props.history.push('/signin');}
						}

						if (e.response === undefined || (e.response.status !== 401 && e.response.status !== 400)) {
							console.log(e);
						}
					}
				});
		}
		else {
			return axios({
				method: method,
				url: process.env.REACT_APP_API_URL + url,
				data: params,
				params: method === "GET" ? params : null,
				headers: headers,
				cancelToken: cancelToken
			})
				.then(data => {
					if (data.data === "INTERNAL_ERROR") {
						throw new Error(data);
					}
					else if (callback != null) {
						callback(data);
					}
				})
				.catch(e => {
					if (errorCallback != null) {
						errorCallback(e);
					}
					else {
						if (cancelToken === null && e.message.indexOf("Request aborted") === -1) {
							window.localStorage.removeItem("jwt");
							if(component != null){
							component.props.data.username = "";
							component.props.history.push('/signin');}
						}

						if (e.response === undefined || (e.response.status !== 401 && e.response.status !== 400)) {
							console.log(e);
						}
					}
				});
		}
	}

}