import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import {withRouter} from "react-router-dom";
import FormSelect from '../components/form-select';
import GraphBar from '../components/graph-bar';

import GraphDoughnut from '../components/graph-doughnut';

export default inject("data")(withRouter(observer(class Cashflow extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            monthlyProcessedByTypeData : [49.5, 23.8, 12.4, 10, 4.3],
            monthlyProcessedByTypeLabels : ["London", "New York", "Toronto", "Vancouver", "Amsterdam"],
            total : 1746.47,
            typeSubType : [50, 25, 15, 10],
            typeSubTypeLabel : ["Rent", "Fin Services", "Comp Equip", "Entertaining"],
            dueSoon : [
                {
                    name : "Compagny GHI",
                    amount : 20000,
                    date : "14/07/2021",
                },
                {
                    name : "Compagny JKL",
                    amount : 25000,
                    date : "16/07/2021",
                },
                {
                    name : "Compagny MNO",
                    amount : 23000,
                    date : "16/07/2021",
                },
            ],
            largestInvoices : [
                {
                    name : "Compagny ABC",
                    amount : 20000,
                    date : "31/05/2020",
                },
                {
                    name : "Compagny DEF",
                    amount : 25000,
                    date : "26/04/2020",
                },
                {
                    name : "Compagny GHI",
                    amount : 22000,
                    date : "22/03/2021",
                },
            ],
            reoccurringInvoices : [
                {
                    name : "Compagny 123",
                    amount : 25000,
                    avg : 8333.33,
                    date : "31/05/2020",
                },
                {
                    name : "Compagny 456",
                    amount : 23000,
                    avg : 7666.66,
                    date : "29/04/2020",
                },
                {
                    name : "Compagny 789",
                    amount : 22000,
                    avg : 7333.33,
                    date : "22/03/2020",
                },
            ],
            actual : [80000, 4000, 70000, 15000, 70000, 58000, 71000],
            actualSec : [85000, 35000, 45000, 65000, 33000, 50000, 45000],
            actualLabels : [this.props.data.t.read('month.jan').toUpperCase(), this.props.data.t.read('month.feb').toUpperCase(), this.props.data.t.read('month.mar').toUpperCase(),
                        this.props.data.t.read('month.apr').toUpperCase(), this.props.data.t.read('month.may').toUpperCase(), this.props.data.t.read('month.jun').toUpperCase(),
                        this.props.data.t.read('month.jul').toUpperCase(), this.props.data.t.read('month.aug').toUpperCase(), this.props.data.t.read('month.sep').toUpperCase(),
                        this.props.data.t.read('month.oct').toUpperCase(), this.props.data.t.read('month.nov').toUpperCase(), this.props.data.t.read('month.dec').toUpperCase()],
            forecast : [71000, 65000, 52000, 50000, 45000],
            forecastSec : [33000, 40000, 60000, 40000, 20000],
            forecastLabels : [this.props.data.t.read('month.aug').toUpperCase(), this.props.data.t.read('month.sep').toUpperCase(),
            this.props.data.t.read('month.oct').toUpperCase(), this.props.data.t.read('month.nov').toUpperCase(), this.props.data.t.read('month.dec').toUpperCase()]
        }

        this.state.monthlyProcessedByTypeData = this.state.monthlyProcessedByTypeData.map(v => v/(this.props.data.role == null || ["SuperAdmin", "Admin"].indexOf(this.props.data.role) !== -1 ? 1 : 5))

    }

    render() {
        return  <div className="cashflow-page">
                    <div className="d-flex justify-content-between mb-4 pl-2">
                        <div className="d-flex flex-3">
                            <div className="mr-2">
                                <FormSelect title={this.props.data.t.read("office")} options={[]} optionChanged={option => {} } />
                            </div>
                            <div className="mr-2">
                                <FormSelect title={this.props.data.t.read("dept")} options={[]} optionChanged={option => {} } />
                            </div>
                            <div className="mr-2">
                                <FormSelect title={this.props.data.t.read("currency")} options={[]} optionChanged={option => {} } />
                            </div>
                        </div>
                        <div className="flex-2 d-flex justify-content-end">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-sm btn-light">1{this.props.data.t.read("D")}</button>
                                <button type="button" className="btn btn-sm btn-light">1M</button>
                                <button type="button" className="btn btn-sm btn-light">1{this.props.data.t.read("Q")}</button>
                                <button type="button" className="btn btn-sm btn-primary">1{this.props.data.t.read("Y")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="rounded-box">
                            <div className="d-flex">
                                <div className="flex-7 dashed-border-right">
                                    <div className="mb-2 text-center">
                                        {this.props.data.t.read("actual")}
                                    </div>
                                    <div style={{height:250}}>
                                        <GraphBar dataset={this.state.actual} secondaryDataSet={this.state.actualSec} horizontal={true} showYAxis={true}
                                                color="rgba(242, 164, 111, 0.6)" id="actual-cashflow" hardLine={true} labels={this.state.actualLabels} />
                                    </div>
                                </div>
                                <div className="flex-5">
                                    <div className="mb-2 text-center">
                                        {this.props.data.t.read("forecast")}
                                    </div>
                                    <div style={{height:250}}>
                                        <GraphBar dataset={this.state.forecast} secondaryDataSet={this.state.forecastSec} horizontal={true} showYAxis={true}
                                                color="rgba(0, 93, 183, 0.3)" id="forecast-cashflow" hardLine={true} labels={this.state.forecastLabels} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 text-center">
                                {this.props.data.t.read("cashflow_forecast")}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="rounded-box">
                            <div style={{height:350}}>
                                <GraphDoughnut dataset={this.state.monthlyProcessedByTypeData} showYAxis={true} total={this.state.total.toLocaleString()} totalLabel={this.props.data.t.read("totalProcessed")} label="$" id="monthly-invoice-processed-by-type" labels={this.state.monthlyProcessedByTypeLabels}
                                          color={["#f7911a", "#617de9", "#a3a7a9", "#57acfb", "#9ad0f5", "#ffb1c1"]} />
                            </div>
                            <div className="graph-data-label mt-2">{this.props.data.t.read("office-department")}</div>
                        </div>
                        <div className="rounded-box">
                            <div style={{height:350}}>
                                <GraphBar dataset={this.state.typeSubType} showYAxis={true} color={["#7c9dd6", "#f2a46f", "#9bc67f", "#ffd34d"]} id="type-sub-type" hardLine={true} labels={this.state.typeSubTypeLabel} />
                            </div>
                            <div className="graph-data-label mt-2">{this.props.data.t.read("type-sub-type")}</div>
                        </div>
                        <div className="flex-1 d-flex flex-column">
                            <div className="rounded-box">
                                <table className="width-100p font-xxsmall">
                                    <thead>
                                        <tr className="border-bottom">
                                            <th className="border-right"></th>
                                            <th className="text-center">{this.props.data.t.read("invoice.amount")}</th>
                                            <th className="text-right">{this.props.data.t.read("date_due")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dueSoon.map(due => {
                                                return <tr key={due.name + due.amount} className="semi-faded">
                                                            <td className="border-right">{due.name}</td>
                                                            <td className=" d-flex justify-content-between">
                                                                <div>$</div>
                                                                <div>{due.amount.toLocaleString()}</div>
                                                            </td>
                                                            <td className="text-right">{due.date}</td>
                                                        </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="graph-data-label mt-2">{this.props.data.t.read("due_soon")}</div>
                            </div>
                            <div className="rounded-box">
                                <table className="width-100p font-xxsmall">
                                    <thead>
                                        <tr className="border-bottom">
                                            <th className="border-right"></th>
                                            <th className="text-center">{this.props.data.t.read("invoice.amount")}</th>
                                            <th className="text-right">{this.props.data.t.read("date_due")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.largestInvoices.map(due => {
                                                return <tr key={due.name + due.amount} className="semi-faded">
                                                            <td className="border-right">{due.name}</td>
                                                            <td className=" d-flex justify-content-between">
                                                                <div>$</div>
                                                                <div>{due.amount.toLocaleString()}</div>
                                                            </td>
                                                            <td className="text-right">{due.date}</td>
                                                        </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="graph-data-label mt-2">{this.props.data.t.read("largest_invoices")}</div>
                            </div>
                            <div className="rounded-box">
                                <table className="width-100p font-xxsmall">
                                    <thead>
                                        <tr className="border-bottom">
                                            <th className="border-right"></th>
                                            <th className="text-center">{this.props.data.t.read("cum_amt")}</th>
                                            <th className="text-center">{this.props.data.t.read("avg_invoice_amt")}</th>
                                            <th className="text-right">{this.props.data.t.read("last_invoice")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.reoccurringInvoices.map(due => {
                                                return <tr key={due.name + due.amount} className="semi-faded">
                                                            <td className="border-right">{due.name}</td>
                                                            <td>
                                                                <div className="d-flex justify-content-between">
                                                                    <div>$</div>
                                                                    <div>{due.amount.toLocaleString()}</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="ml-3">$</div>
                                                                    <div>{due.avg.toLocaleString()}</div>
                                                                </div>
                                                            </td>
                                                            <td className="text-right">{due.date}</td>
                                                        </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="graph-data-label mt-2">{this.props.data.t.read("reoccurring_invoices")}</div>
                            </div>
                        </div>
                    </div>
                </div>
    }
})));