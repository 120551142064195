import React, {Component} from 'react';
import {observer, inject} from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';

const lowerAndUpperCase = "LowerAndUpperCase"
const digitalNumber = "DigitalNumber"
const specialChar = "SpecialChar"
const minMaxChar = "MinMaxChar"
const isIdentical = "IsIdentical"
const regexCaseLowUpp = /(?=.*[a-z])(?=.*[A-Z])/;
const regexContainNumber = /(?=.*[\d])/;
const regexSpecialCharacter = /(?=.*?[!$"%?&*().,\-=_+])/;

export default inject("data")(observer(class Signin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            oldPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            errorMessage: null,
            default_lang: this.props.data.t.getCurrentLang(),
            passwordCondition: {
                [lowerAndUpperCase]: {label: this.props.data.t.read("password_compliance.low_up_case"), isValid: false},
                [digitalNumber]: {label: this.props.data.t.read("password_compliance.digit_number"), isValid: false},
                [specialChar]: {label: this.props.data.t.read("password_compliance.special_char"), isValid: false},
                [minMaxChar]: {label: this.props.data.t.read("password_compliance.min_max_char"), isValid: false},
                [isIdentical]: {label: this.props.data.t.read("password_compliance.is_identical"), isValid: false}
            }
        }
        this.updatePasswordCondition = this.updatePasswordCondition.bind(this)
        this.checkPasswordCompliance = this.checkPasswordCompliance.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        // Needed to update labels, without this when changing language on this page, password condition labels doesn't update their value
        if (this.props.data.t.getCurrentLang() !== prevState.default_lang) {
            this.setState(prevState => ({
                default_lang: this.props.data.t.getCurrentLang(),
                passwordCondition: {
                    [lowerAndUpperCase]: {label: this.props.data.t.read("password_compliance.low_up_case"), isValid: prevState.passwordCondition[lowerAndUpperCase].isValid},
                    [digitalNumber]: {label: this.props.data.t.read("password_compliance.digit_number"), isValid: prevState.passwordCondition[digitalNumber].isValid},
                    [specialChar]: {label: this.props.data.t.read("password_compliance.special_char"), isValid: prevState.passwordCondition[specialChar].isValid},
                    [minMaxChar]: {label: this.props.data.t.read("password_compliance.min_max_char"), isValid: prevState.passwordCondition[minMaxChar].isValid},
                    [isIdentical]: {label: this.props.data.t.read("password_compliance.is_identical"), isValid: prevState.passwordCondition[isIdentical].isValid}
                }
            }))
        }
    }

    updatePasswordCondition(key, isValid) {
        this.setState(prevState => ({
            passwordCondition: {
                ...prevState.passwordCondition, [key]: {
                    ...prevState.passwordCondition[key], isValid: isValid
                }
            }
        }))
    };

    checkPasswordCompliance() {
        // Check if password length > 10
        const isPasswordLengthMinValid = (this.state.newPassword.length >= 10 && this.state.newPassword.length <= 64)
        this.updatePasswordCondition(minMaxChar, isPasswordLengthMinValid);

        // Check if password have a Maj and lower
        const isPasswordMajMinValid = (regexCaseLowUpp.test(this.state.newPassword));
        this.updatePasswordCondition(lowerAndUpperCase, isPasswordMajMinValid);

        // Check if password have a number
        const isPasswordNumberValid = (regexContainNumber.test(this.state.newPassword));
        this.updatePasswordCondition(digitalNumber, isPasswordNumberValid);

        // Check if password have a special character !$"%?&*().,-=_+
        const isPasswordSpecialCharacterValid = (regexSpecialCharacter.test(this.state.newPassword));
        this.updatePasswordCondition(specialChar, isPasswordSpecialCharacterValid);

        // New password confirmation is different
        const isPasswordIdenticalValid = (this.state.newPassword === this.state.newPasswordConfirm && this.state.newPasswordConfirm.trim() !== '' && this.state.newPassword.trim() !== '');
        this.updatePasswordCondition(isIdentical, isPasswordIdenticalValid);
    }

    updatePassword() {
        this.setState({
            errorMessage: null
        }, () => {
            let data = new FormData();
            data.append("oldPassword", this.state.oldPassword);
            data.append("newPassword", this.state.newPassword);

            ApiWrapper.fetch(this, "/auth/update-password", "POST", data, response => {
                this.setState({
                    successMessage: this.props.data.t.read("setting_password.password_updated"),
                    oldPassword: "",
                    newPassword: "",
                    newPasswordConfirm: ""
                })
            }, error => {
                this.setState({
                    errorMessage: this.props.data.t.read("errors." + error.response.data)
                })
            })
        })
    }

    handleUpdatePasswordSubmit(event) {
        event.preventDefault();
        this.checkPasswordCompliance()
        if (Object.values(this.state.passwordCondition).every(value => value.isValid === true)) {
            this.updatePassword()
        } else {
            this.setState({
                errorMessage: this.props.data.t.read("errors.requirements_not_fulfilled")
            })
        }
    }

    render() {
        return <div className="container">
            <div className="row">
                <h3 className="font-weight-bold">{this.props.data.t.read("settings")}</h3>
            </div>
            <div className="row">
                <div className="col-md-6 mw-500px">
                    <h4 className="mt-4 mb-3">{this.props.data.t.read("general_info")}</h4>
                    <div className="row">
                        <div className="col-md-4">
                            {this.props.data.t.read("first_name")} :
                        </div>
                        <div className="col-md-8">
                            {this.props.data.firstName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            {this.props.data.t.read("last_name")} :
                        </div>
                        <div className="col-md-8">
                            {this.props.data.lastName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            {this.props.data.t.read("unit")} :
                        </div>
                        <div className="col-md-8">
                            {this.props.data.unit}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 mw-400px mt-5 mb-3">
                    <h4>{this.props.data.t.read("setting_password.change_password")}</h4>
                    <div className="alert-section">
                        {this.state.errorMessage != null ? <div className="alert alert-danger" role="alert">
                            {this.state.errorMessage}
                        </div> : this.state.successMessage != null ? <div className="alert alert-success" role="alert">
                            {this.state.successMessage}
                        </div> : null}
                    </div>
                    <form className="" method="post" onSubmit={(e) => {
                        this.handleUpdatePasswordSubmit(e)
                    }} autoComplete="off">
                        <div className="form-group input-group-sm">
                            <input className="form-control"
                                   placeholder={this.props.data.t.read("setting_password.old_password")}
                                   required type="password" name="oldPassword" value={this.state.oldPassword}
                                   onChange={(event) => this.setState({oldPassword: event.target.value})}/>
                        </div>
                        <div className="form-group input-group-sm mt-4">
                            <input className="form-control"
                                   placeholder={this.props.data.t.read("setting_password.new_password")}
                                   required type="password" name="password" value={this.state.newPassword}
                                   onChange={(event) => this.setState({newPassword: event.target.value}, () => this.checkPasswordCompliance())}/>
                        </div>
                        <div className="form-group input-group-sm">
                            <input className="form-control"
                                   placeholder={this.props.data.t.read("setting_password.new_password_confirm")}
                                   required type="password" name="passwordConfirm" value={this.state.newPasswordConfirm}
                                   onChange={(event) => this.setState({newPasswordConfirm: event.target.value}, () => this.checkPasswordCompliance())}/>
                        </div>
                        <div>
                            <h5>{this.props.data.t.read("password_compliance.description")}</h5>
                            <ul>
                                {Object.entries(this.state.passwordCondition).map(([key, value]) => (
                                    <li className={value.isValid ? 'password-check-green' : 'password-check-default'}
                                        key={key}>
                                        {value.label}
                                    </li>))}
                            </ul>
                        </div>
                        <div className="form-group">
                            <button
                                className="btn btn-block btn-primary">{this.props.data.t.read("setting_password.update_password")}</button>
                        </div>
                    </form>
                </div>
                <div className="col-md-6 mt-5 mb-3">
                    <ul>
                        {this.props.data.t.read("password_compliance.indications")
                            .map((item, i) => <li key={i} className="settings-conformity-text">{item}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    }
}));