import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import ApiWrapper from './../../utils/api-wrapper';
import Modal from './../../components/modal';
import SupplierUnitsCodes from '../../components/supplier-units-codes';
import MoneyInput from '../../components/money-input';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faSync, faCog } from '@fortawesome/free-solid-svg-icons';

export default inject("data")(withRouter(observer(class AdminClients extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            successMessage: null,
            client: null,
            users: [],
            clients: [],
            codeClientId: null,
            unitClientId: null,
            showImportSuppliers: false,
            importFile: null
        }
    }

    componentDidMount() {
        this.refreshClients();
        this.fetchComponents();
    }

    refreshClients() {
        ApiWrapper.fetch(this, "/clients", "GET", {},
            response => {
                let clients = response.data;
                let client = null;

                this.setState({
                    client: client,
                    clients: clients
                })
            })
    }

    fetchComponents() {
        ApiWrapper.fetch(this, "/clients/components", "GET", {},
            response => {
                let components = response.data;
                this.setState({
                    components: components,
                })
            })
    }

    generatePassword(i, user) {
        this.updateUser(i, {...user, is_generating: true})
        const data = new FormData();
        data.append("user", JSON.stringify(user));

        ApiWrapper.fetch(this, "/users/generate_password", "PUT", data,
            response => {
                this.setState({
                    successMessage: `${user.first_name} ${user.last_name} \nGénération de mot de passe temporaire success`
                })
                this.updateUser(i, {...user, is_generating: false})
            },
            response => {
                this.setState({
                    errorMessage: `${user.first_name} ${user.last_name} \nGénération de mot de passe temporaire echec`
                })
                this.updateUser(i, {...user, is_generating: false});
            })
    }

    handleSubmit(event) {
        event.preventDefault();

        let data = new FormData();
        data.append("id", this.state.client._id ?? "");
        data.append("name", this.state.client.name);
        data.append("emails", this.state.client.emails ?? "");

        data.append("config", JSON.stringify(this.state.client.config));
        data.append("supplier_codes", JSON.stringify(this.state.client.supplier_codes));
        data.append("supplier_units", JSON.stringify(this.state.client.supplier_units));
        data.append("users", JSON.stringify(this.state.users));

        ApiWrapper.fetch(this, "/clients", "POST", data,
            response => {
                this.setState({
                    successMessage: "Sauvegarde complétée"
                }, () => {
                    this.refreshClients();
                })
            },
            response => {
                this.setState({
                    errorMessage: "Erreur lors de la sauvegarde"
                })
            })
    }

    addClient() {
        this.setState({
            client: {
                name: "",
                emails: "",
                supplier_codes: [],
                supplier_units: [],
                users: [],
                config: {
                    type: "legal",
                    engine: "default",
                    frontend_invoice: {
                        hide_na_fields: true
                    },
                    components: [],
                    variance: null,
                    roles: ["SuperAdmin", "Admin", "Manager", "Clerk"],
                    invoice_exporter: {
                        group_invoice_by: "",
                        export_supplier: true,
                        detail_on_multiple_line: false,
                        column_amount: 0,
                        re_export_supplier: false,
                        csv_line_template_invoice: "",
                        csv_line_template_supplier: "",
                        taxes_gl: "",
                        separator: ";",
                        decimal_separator: ".",
                        details_column_amount: 0
                    }
                },
            }
        })
    }

    updateField(fieldName, value, subField = null, subSubField = null) {
        let client = this.state.client;

        if (subSubField != null) {
            if (client[fieldName][subField] == null) {
                client[fieldName][subField] = {}
            }

            client[fieldName][subField][subSubField] = value
        }
        else if (subField == null) {
            client[fieldName] = value;
        }
        else {
            client[fieldName][subField] = value;
        }

        this.setState({
            client: client
        })
    }

    updateCode(idx, fieldName, value) {
        let client = this.state.client;
        client.supplier_codes[idx][fieldName] = value;

        this.setState({
            client: client
        })
    }

    removeCode(idx) {
        let client = this.state.client;
        client.supplier_codes.splice(idx, 1);

        this.setState({
            client: client
        })
    }

    addCode() {
        let client = this.state.client;
        client.supplier_codes.push({ code: "", code_gl: "", desc: "" });

        this.setState({
            client: client
        })
    }

    updateUnit(idx, fieldName, value) {
        let client = this.state.client;
        client.supplier_units[idx][fieldName] = value;

        this.setState({
            client: client
        })
    }

    removeUnit(idx) {
        let client = this.state.client;
        client.supplier_units.splice(idx, 1);

        this.setState({
            client: client
        })
    }

    addUnit() {
        let client = this.state.client;
        client.supplier_units.push({ unit: "", desc: "" });

        this.setState({
            client: client
        })
    }

    addUser() {
        const users = this.state.users;
        users.push({ "id_client": this.state.client._id, username: "", active: true, password: "", first_name: "", last_name: "", unit: "ALL", "role": "Clerk" });

        this.setState({
            users: users
        })
    }

    updateUser(i, user) {
        const users = this.state.users;
        users[i] = user;

        this.setState({
            users: users
        })
    }

    toggleFrontendSection(component) {
        let client = this.state.client;
        let idx = client.config.components.findIndex(comp => comp.id === component._id);

        if (idx === -1) {
            client.config.components.push({ name: component.name, id: component._id });
        }
        else {
            client.config.components.splice(idx, 1);
        }

        this.setState({
            client: client
        })
    }

    removeUser(idx) {
        let users = this.state.users;
        users.splice(idx, 1);

        this.setState({
            users: users
        })
    }

    prepareImportSuppliers(f) {
        if (f.length > 0) {
            this.setState({
                importFile: f[0],
            })
        }
    }

    processImportSuppliers() {
        if (this.state.importFile != null) {
            const data = new FormData();
            data.append("csv", this.state.importFile);
            data.append("id_client", this.state.codeClientId);

            ApiWrapper.fetch(this, "/suppliers/client-import", "POST", data,
                response => {
                    this.setState({
                        showImportSuppliers: false,
                        importFile: null,
                    }, () => {
                        this.refreshClients();
                    })
                },
                null, true)
        }
    }

    toggleSelectAll(checkbox) {
        document.querySelectorAll(".supplier-code-checkbox").forEach(elem => {
            if (checkbox.checked) {
                elem.checked = true;
            }
            else {
                elem.checked = false;
            }
        });

        this.updateCodeActionButtons();
    }

    updateCodeActionButtons() {
        let count = 0;
        let notSelectedCount = 0;

        document.querySelectorAll(".supplier-code-checkbox").forEach(elem => {
            if (elem.checked) {
                count++;
            }
            else {
                notSelectedCount++;
            }
        });

        document.querySelectorAll(".code-action-button").forEach(elem => {
            if (count > 0) {
                elem.classList.remove("faded")
            }
            else {
                elem.classList.add("faded")
            }
        });

        if (notSelectedCount > 0) {
            document.querySelector("#all-selector-checkbox").checked = false;
        }
    }

    cancelAction() {
        if (this.props.data.role === "SuperAdmin")
            this.setState({ client: null });
        else
            this.props.history.goBack();
    }

    removeBatchCodes() {
        let codes = this.state.client.supplier_codes;

        document.querySelectorAll(".supplier-code-checkbox").forEach(elem => {
            if (elem.checked) {
                let idx = -1;
                codes.forEach((c, i) => {
                    if (c.code === elem.value) {
                        idx = i;
                    }
                })

                if (idx !== -1) {
                    codes.splice(idx, 1);
                }
            }
        });

        let client = this.state.client;
        client.supplier_codes = codes;

        this.setState({
            client: client
        }, () => {
            document.querySelectorAll(".supplier-code-checkbox").forEach(elem => {
                elem.checked = false;
            });

            this.updateCodeActionButtons();
        });
    }

    userCanModify(user) {
        return this.state.client.config.roles.indexOf(user.role) >= this.state.client.config.roles.indexOf(this.props.data.role);
    }

    availableRoles(user) {
        let id = this.state.client.config.roles.indexOf(this.props.data.role);
        if (!this.userCanModify(user))
            id = 0

        let roles = this.state.client.config.roles;
        let returnRoles = [];

        for (let i = id; i < roles.length; i++)
            returnRoles.push(roles[i]);

        return returnRoles;
    }

    toggleExporterOption(option) {
        let client = this.state.client;
        client.config.invoice_exporter[option] = !(client.config.invoice_exporter[option]);
        this.setState({
            client: client
        })
    }

    changeColAmountExporter(e) {
        let client = this.state.client;
        client.config.invoice_exporter.column_amount = parseInt(e.target.value);
        this.setState({
            client: client
        })
    }

    getHardCodedValueExporter(n) {
        let arr = this.state.client.config.invoice_exporter.csv_line_template_invoice.split(";");

        return arr[n].split(":")[1];
    }

    getInvoiceExporterOption(option) {
        return this.state.client.config.invoice_exporter &&
            this.state.client.config.invoice_exporter[option] ?
            this.state.client.config.invoice_exporter[option]
            :
            0;
    }

    changeExporterValues(field, e) {
        let client = this.state.client;
        client.config.invoice_exporter[field] = e.target.value;
        this.setState({
            client: client
        })
    }

    getColumnValueExporter(n) {
        let arr = this.state.client.config.invoice_exporter.csv_line_template_invoice.split(";");
        if (arr[n] && arr[n].includes("HardCoded:"))
            return "HardCoded"
        return arr[n];
    }

    selectClient(client){
        this.setState({ client: JSON.parse(JSON.stringify(client)) })

        this.refreshUsers(client);
    }


    refreshUsers(client = this.state.client) {
        const filters = [{ id: "id_client", value: client._id }];
        const sorting = [{ id: "first_name", desc: false }, { id: "last_name", desc: false }];

        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        ApiWrapper.fetch(this, `/users/${searchData}`, "GET", {},
            response => {
                this.setState({
                    users: response.data.users
                });
            });
    }

    changeColumnValueExporter(value, n) {
        let client = this.state.client;
        let arr = client.config.invoice_exporter.csv_line_template_invoice.split(";");
        arr[n] = value;
        let str = arr.join(";");
        client.config.invoice_exporter.csv_line_template_invoice = str;
        this.setState({
            client: client
        })
    }

    updateVariance(variance) {
        let client = this.state.client;
        client.config.variance = variance;
        this.setState({ client: client })
    }

    updateCostType(cost_type) {
        let client = this.state.client;
        client.config.cost_type = cost_type;
        this.setState({ client: client })
    }

    updateLogo(files) {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            const client = this.state.client;
            reader.onloadend = () => {
                client.config.logo = reader.result;
                this.setState({ client: client });
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return <div>
            {
                this.state.errorMessage != null ?
                    <Modal title="Attention" okText={"OK"} okAction={() => this.setState({ errorMessage: null })}>
                        <p style={{ whiteSpace: 'pre-line' }} className='text-danger'>{this.state.errorMessage}</p>
                    </Modal>
                    :
                    this.state.successMessage != null ?
                        <Modal title="Attention" okText={"OK"} okAction={() => this.setState({ successMessage: null })}>
                            {this.state.successMessage}
                        </Modal>
                        :
                        this.state.showImportSuppliers ?
                            <Modal key="import-modal" width="50vw" title="Importer des fournisseurs" okText={"Importer"} okAction={() => this.processImportSuppliers()} cancelText={"Annuler"} cancelAction={() => this.setState({ showImportSuppliers: false, importFile: null })} >
                                <div>
                                    Format du fichier CSV :
                                    <div className='card p-2'>
                                        code fournisseur; numero fournisseur; code gl; adresse; pays; code postal; nom1; nom2; ...; nomX<br />
                                        code fournisseur; numero fournisseur; code gl; adresse; pays; code postal; nom1; nom2; ...; nomX<br />
                                        code fournisseur; numero fournisseur; code gl; adresse; pays; code postal; nom1; nom2; ...; nomX<br />
                                    </div>
                                    <div className='mt-2'>
                                        <input className='form-control' type="file" id="import-csv-file" onChange={f => this.prepareImportSuppliers(f.target.files)} />
                                    </div>
                                </div>
                            </Modal>
                            :
                            this.state.codeClientId != null ?
                                <Modal title="Association de codes fournisseur" okText={"OK"} titleText="Importer" titleAction={() => this.setState({ showImportSuppliers: true })} okAction={() => this.setState({ codeClientId: null })}>
                                    <SupplierUnitsCodes type={"codes"} clientId={this.state.codeClientId} />
                                </Modal>
                                :
                                this.state.unitClientId != null ?
                                    <Modal title="Association d'unités/bureaux" okText={"OK"} okAction={() => this.setState({ unitClientId: null })}>
                                        <SupplierUnitsCodes type={"units"} clientId={this.state.unitClientId} />
                                    </Modal>
                                    :
                                    this.state.client != null ?
                                        <form method="post" onSubmit={(e) => { this.handleSubmit(e) }}>
                                            <Modal width="60vw" title={this.state.client.name ?? "Ajout d'un client"} okIsSubmit={true} cancelAction={() => this.cancelAction()} okText={this.props.data.t.read("save")} cancelText={this.props.data.t.read("cancel")}>
                                                <div className='mb-2'>
                                                    <input required placeholder={"Nom du client"} className='form-control' type="text" value={this.state.client.name} onChange={e => this.updateField("name", e.target.value)} />
                                                </div>
                                                <div className='mb-2'>
                                                    <input placeholder={"Email du client"} className='form-control' type="text" value={this.state.client.emails || ""} onChange={e => this.updateField("emails", e.target.value)} />
                                                </div>
                                                <div className='mb-2'>
                                                    <h5 className='mt-4'>
                                                        Configuration:
                                                    </h5>
                                                    <div className='row mt-1'>
                                                        <div className='col-2'>Type : </div>
                                                        <div className='col-10'>
                                                            <select required className='form-control' value={this.state.client.config.type} onChange={e => this.updateField("config", e.target.value, "type")}>
                                                                <option value="">--</option>
                                                                <option value="legal">Légal</option>
                                                                <option value="resto">Restaurant</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-1'>
                                                        <div className='col-2'>Engin : </div>
                                                        <div className='col-10'>
                                                            <select required className='form-control' value={this.state.client.config.engine} onChange={e => this.updateField("config", e.target.value, "engine")}>
                                                                <option value="">--</option>
                                                                <option value="default">Défaut</option>
                                                                <option value="juris">Juris</option>
                                                                <option value="demo">Démo</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.client.config.frontend_invoice != null ?
                                                            <div className='row mt-1'>
                                                                <div className='col-2'>Frontend : </div>
                                                                <div className='col-10'>
                                                                    <div className='row font-small'>
                                                                        <div className='col-6'>
                                                                            <label className='d-block' >
                                                                                <input type="checkbox" defaultChecked={this.state.client.config.frontend_invoice.hide_na_fields} onClick={() => this.updateField("config", !this.state.client.config.frontend_invoice.hide_na_fields, "frontend_invoice", "hide_na_fields")} /> Cacher les champs sans valeurs
                                                                            </label>
                                                                            <label className='d-block' >
                                                                                <input type="checkbox" defaultChecked={this.state.client.config.frontend_invoice.hide_cost_details_table} onClick={() => this.updateField("config", !this.state.client.config.frontend_invoice.hide_cost_details_table, "frontend_invoice", "hide_cost_details_table")} /> Cacher la table "Détails coûts"
                                                                            </label>
                                                                            <label className='d-block' >
                                                                                <input type="checkbox" defaultChecked={this.state.client.config.frontend_invoice.hide_work_date} onClick={() => this.updateField("config", !this.state.client.config.frontend_invoice.hide_work_date, "frontend_invoice", "hide_work_date")} /> Cacher "Date de travail"
                                                                            </label>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <label className='d-block' >
                                                                                <input type="checkbox" defaultChecked={this.state.client.config.frontend_invoice.hide_office_in_gl_details_table} onClick={() => this.updateField("config", !this.state.client.config.frontend_invoice.hide_office_in_gl_details_table, "frontend_invoice", "hide_office_in_gl_details_table")} /> Cacher "Bureau" de la table "Détails GL"
                                                                            </label>
                                                                            <label className='d-block' >
                                                                                <input type="checkbox" defaultChecked={this.state.client.config.frontend_invoice.hide_due_date} onClick={() => this.updateField("config", !this.state.client.config.frontend_invoice.hide_due_date, "frontend_invoice", "hide_due_date")} /> Cacher "Date d'échéance"
                                                                            </label>                                          
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <div className='row mt-1'>
                                                        <div className='col-2'>Modules : </div>
                                                        <div className='col-10'>
                                                            <div className='row'>
                                                                {
                                                                    this.state.components.map(component => {
                                                                        return <div key={component._id} className='col-2'>
                                                                            <label>
                                                                                <input type="checkbox" defaultChecked={this.state.client.config.components.findIndex(comp => component._id === comp.id) !== -1} onClick={() => this.toggleFrontendSection(component)} /> {component.name}
                                                                            </label>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-1'>
                                                        <div className='col-2'>{this.props.data.t.read("fiscal_year")} : </div>
                                                        <div className='ml-3'>
                                                            <select value={this.state.client.config.fiscal_year_start} onChange={e => this.updateField("config", e.target.value, "fiscal_year_start")} className='form-control' >
                                                                <option value="" disabled>{this.props.data.t.read("start_fiscal_year")}</option>
                                                                <option value="01">{this.props.data.t.read("month.January")}</option>
                                                                <option value="02">{this.props.data.t.read("month.February")}</option>
                                                                <option value="03">{this.props.data.t.read("month.March")}</option>
                                                                <option value="04">{this.props.data.t.read("month.April")}</option>
                                                                <option value="05">{this.props.data.t.read("month.May")}</option>
                                                                <option value="06">{this.props.data.t.read("month.June")}</option>
                                                                <option value="07">{this.props.data.t.read("month.July")}</option>
                                                                <option value="08">{this.props.data.t.read("month.August")}</option>
                                                                <option value="09">{this.props.data.t.read("month.September")}</option>
                                                                <option value="10">{this.props.data.t.read("month.October")}</option>
                                                                <option value="11">{this.props.data.t.read("month.November")}</option>
                                                                <option value="12">{this.props.data.t.read("month.December")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-1'>
                                                        <div className='col-2'>
                                                            {this.props.data.t.read("default_cost_type")} :
                                                        </div>
                                                        <div className='ml-3'>
                                                            <input value={this.state.client.config.cost_type} onChange={e => this.updateCostType(e.target.value)}  placeholder={this.props.data.t.read("default_cost_type")} />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-1'>
                                                        <div className='col-2'>
                                                            {this.props.data.t.read("Variance")} :
                                                        </div>
                                                        <div className='ml-3'>
                                                            <MoneyInput value={this.state.client.config.variance} onChange={value => this.updateVariance(value)} locale={`${this.props.data.t.lang}-${this.props.data.t.lang.toUpperCase()}`} placeholder="variance" />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-2'>
                                                        <div className='col-2'>Invoice Export : </div>
                                                        <div className='col-10'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    {
                                                                        ["export_supplier", "detail_on_multiple_line", "re_export_supplier"].map(option => {
                                                                            return <div key={option} className='row mt-1'>
                                                                                <div className='col-5'>
                                                                                    {this.props.data.t.read("client.csv_exporter." + option)}
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <input type="checkbox" defaultChecked={this.getInvoiceExporterOption(option)} onClick={() => this.toggleExporterOption(option)} name={option} />
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }

                                                                    <hr />
                                                                    <div key={"separator_csv"} className='row mt-2'>
                                                                        <div className='col-5'>
                                                                            {this.props.data.t.read("client.csv_exporter.separator")}
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <input type="text" value={this.state.client.config.invoice_exporter["separator"]} onChange={e => this.changeExporterValues("separator", e)} name={"separator"} placeholder="" />
                                                                        </div>
                                                                    </div>

                                                                    <div key={"decimal_separator_csv"} className='row mt-2'>
                                                                        <div className='col-5'>
                                                                            {this.props.data.t.read("client.csv_exporter.decimal_separator")}
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <input type="text" value={this.state.client.config.invoice_exporter["decimal_separator"]} onChange={e => this.changeExporterValues("decimal_separator", e)} name={"decimal_separator"} placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                    <hr />

                                                                    <div key={"unit_exporter"} className='row mt-1'>
                                                                        <div className='col-5'>
                                                                            {this.props.data.t.read("client.csv_exporter.group_invoice_by")}
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <select value={this.state.client.config.invoice_exporter.group_invoice_by} onChange={e => this.changeExporterValues("group_invoice_by", e)}>
                                                                                {
                                                                                    ["", "unit"].map(option => {
                                                                                        return (
                                                                                            <option key={option} value={option}>{option !== "" ? this.props.data.t.read("client.csv_exporter.group_options." + option) : ""}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div key={"details_column_amount"} className='row mt-2'>
                                                                        <div className='col-5'>
                                                                            {this.props.data.t.read("client.csv_exporter.details_column_amount")}
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <input type="number" value={this.getInvoiceExporterOption("details_column_amount")} onChange={e => this.changeExporterValues("details_column_amount", e)} onKeyDown={e => e.preventDefault()} name={"details_column_amount"} min="0" />
                                                                        </div>
                                                                    </div>

                                                                    <div key={"column_amount"} className='row mt-2'>
                                                                        <div className='col-5'>
                                                                            {this.props.data.t.read("client.csv_exporter.column_amount")}
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <input type="number" value={this.getInvoiceExporterOption("column_amount")} onChange={e => this.changeColAmountExporter(e)} onKeyDown={e => e.preventDefault()} name={"column_amount"} min="0" />
                                                                        </div>
                                                                    </div>

                                                                    <div key={"taxes_gl"} className='row mt-2'>
                                                                        <div className='col-5'>
                                                                            {this.props.data.t.read("client.csv_exporter.taxes_gl")}
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <input type="text" value={this.state.client.config.invoice_exporter["taxes_gl"]} onChange={e => this.changeExporterValues("taxes_gl", e)} name={"taxes_gl"} placeholder="Example 2103,2104" />
                                                                        </div>
                                                                    </div>

                                                                    <div className='row mt-1'>
                                                                        {
                                                                            Array.from(Array(this.state.client.config.invoice_exporter.column_amount).keys()).map(n => {
                                                                                return (
                                                                                    <div className='col-4 mt-3' key={"column-" + n}>
                                                                                        <label htmlFor={"column-" + n}>{"Column #" + (n + 1)}</label><br />
                                                                                        <select name={"column-" + n} value={this.getColumnValueExporter(n)} onChange={e => this.changeColumnValueExporter(e.target.value, n)}>
                                                                                            <option key={"no_data"} value={""}></option>
                                                                                            <option key={"hard_coded"} value={"HardCoded"}>HardCoded</option>
                                                                                            <optgroup label="Invoice">
                                                                                                {
                                                                                                    [
                                                                                                        "supplier_name",
                                                                                                        "supplier_number",
                                                                                                        "location",
                                                                                                        "invoice_number",
                                                                                                        "invoice_date",
                                                                                                        "invoice_due_date",
                                                                                                        "invoice_total",
                                                                                                        "comments"
                                                                                                    ].map(option => {
                                                                                                        return (
                                                                                                            <option key={option} value={option}>{option !== "" ? this.props.data.t.read("client.csv_exporter.options." + option) : ""}</option>
                                                                                                        );
                                                                                                    })
                                                                                                }
                                                                                            </optgroup>
                                                                                            <optgroup label="Details">
                                                                                                {
                                                                                                    [
                                                                                                        "detail_gl_code",
                                                                                                        "detail_description",
                                                                                                        "detail_qty",
                                                                                                        "detail_unit_price",
                                                                                                        "detail_total",
                                                                                                        "detail_work_date",
                                                                                                        "detail_location"
                                                                                                    ].map(option => {
                                                                                                        return (
                                                                                                            <option key={option} value={option}>{option !== "" ? this.props.data.t.read("client.csv_exporter.options." + option) : ""}</option>
                                                                                                        );
                                                                                                    })
                                                                                                }
                                                                                            </optgroup>
                                                                                        </select>
                                                                                        {
                                                                                            this.getColumnValueExporter(n) === "HardCoded" ?
                                                                                                <input className='mt-1' type="text" value={this.getHardCodedValueExporter(n)} onChange={e => this.changeColumnValueExporter("HardCoded:" + e.target.value, n)} name={"HardCoded:" + n} />
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mb-2'>
                                                    <h5 className='mt-4'>
                                                        Usagers
                                                        <button className='btn btn-info btn-xs ml-2' type="button" onClick={() => this.addUser()}>+</button>
                                                    </h5>
                                                    <table className='table table-sm font-small'>
                                                        <thead>
                                                            <tr>
                                                                <th>Actif</th>
                                                                <th>Email</th>
                                                                <th>Générer MdP</th>
                                                                <th>Prénom</th>
                                                                <th>Nom</th>
                                                                <th>Rôle</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.users.map((user,i) => {
                                                                    return <tr hidden={user?.is_deleted} key={i}>
                                                                        <td>
                                                                            <Switch className='switch mt-0'
                                                                                checked={user.active}
                                                                                disabled={!this.userCanModify(user)}
                                                                                width={70}
                                                                                onColor="#F39800"
                                                                                onHandleColor="#2693e6"                                
                                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                onChange={(active) => this.updateUser(i, {...user, active: active})}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input disabled={!this.userCanModify(user)} required={true && !user?.is_deleted} placeholder={"Email"} style={{ maxWidth: "150px" }} className='form-control form-control-sm' type="email" value={user.email ? user.email : ""} onChange={e => this.updateUser(i, {...user, email: e.target.value})} />
                                                                        </td>
                                                                        <td className='d-flex justify-content-center'>
                                                                            <button className="btn btn-sm btn-primary"  disabled={!this.userCanModify(user) | user.is_generating | !user._id} onClick={() => this.generatePassword(i, user)}>
                                                                                <FontAwesomeIcon className={user.is_generating ? "mr-2 infinite-rotate" : "mr-2" } icon={faCog} />
                                                                                <span>MdP</span>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <input disabled={!this.userCanModify(user)} required = {!user?.is_deleted} placeholder={"Prénom"} style={{ maxWidth: "120px" }} className='form-control form-control-sm' type="text" value={user.first_name} onChange={e => this.updateUser(i, {...user, first_name: e.target.value})} />
                                                                        </td>
                                                                        <td>
                                                                            <input disabled={!this.userCanModify(user)} required = {!user?.is_deleted} placeholder={"Nom"} style={{ maxWidth: "120px" }} className='form-control form-control-sm' type="text" value={user.last_name} onChange={e => this.updateUser(i , {...user, last_name: e.target.value})} />
                                                                        </td>
                                                                        <td>
                                                                            <select disabled={!this.userCanModify(user)} value={user.role} style={{ maxWidth: "120px" }} onChange={e => this.updateUser(i, {...user, role: e.target.value})} className='form-control form-control-sm test'>
                                                                                {
                                                                                    this.availableRoles(user).map(role => {
                                                                                        return <option key={role} value={role} disabled = { role== "SuperAdmin"}  >{role}</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </td>
                                                                        
                                                                        <td>
                                                                            <button disabled={!this.userCanModify(user)} type="button" className="btn btn-sm btn-link" onClick={() => this.updateUser(i, {...user, is_deleted: true})} >
                                                                                <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className='mb-2'>
                                                    <h5 className='mt-4'>
                                                        Liste d'unités/bureaux:
                                                        <button className='btn btn-info btn-xs ml-2' type="button" onClick={() => this.addUnit()}>+</button>
                                                    </h5>
                                                    {
                                                        this.state.client.supplier_units.map((unit, i) => {
                                                            return <div key={i}>
                                                                <div className='row mb-1'>
                                                                    <div className='col-3'>
                                                                        <input required placeholder={"Unité/Bureau"} className='form-control' type="text" value={unit.unit} onChange={e => this.updateUnit(i, "unit", e.target.value)} />
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input required placeholder={"Description"} className='form-control' type="text" value={unit.desc} onChange={e => this.updateUnit(i, "desc", e.target.value)} />
                                                                    </div>
                                                                    <div className='col-2 text-right pt-1'>
                                                                        <button type="button" className="btn btn-link" onClick={() => this.removeUnit(i)} >
                                                                                <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className='col-3'>Logo : </div>
                                                    {this.state.client.config.logo && (
                                                        <div className='mr-2'>
                                                            <img src={this.state.client.config.logo} alt="Selected" style={{ width: '200px', height: '50px' }} />
                                                        </div>
                                                    )}
                                                    <div>
                                                        <label className="btn btn-secondary btn-no-border-radius btn-primary-shadow pl-5 pr-5">
                                                            {this.props.data.t.read("browse")}
                                                            <input type="file" style={{ display: "none" }} onChange={evt => this.updateLogo(evt.target.files)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </form>
                                        :
                                        null
            }

            {
                this.props.data.role === "SuperAdmin" ?
                    <>
                        <h2>
                            Gestion des clients
                            <button className='btn btn-info btn-sm float-right' onClick={() => this.addClient()}>Ajouter</button>
                        </h2>
                        <table className='table table-striped table-sm font-small'>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Nombre d'usagers</th>
                                    <th>Créé le</th>
                                    <th>Dernière modification le</th>
                                    <th className='text-center'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    this.state.clients.map(client => {
                                        return <tr key={client._id}>
                                            <td>{client.name}</td>
                                            <td>{client.users_count}</td>
                                            <td>{client.created_at ? new Date(client.created_at).toLocaleString("fr-CA") : ""}</td>
                                            <td>{client.updated_at ? new Date(client.updated_at).toLocaleString("fr-CA") : ""}</td>
                                            <td className='text-center'>
                                                <button className='btn btn-primary btn-sm' onClick={() => this.selectClient(client)}>Modifier</button>
                                                <button className='btn btn-info btn-sm ml-1' onClick={() => this.setState({ codeClientId: client._id })}>Codes fourn.</button>
                                                <button className='btn btn-secondary btn-sm ml-1' onClick={() => this.setState({ unitClientId: client._id })}>Unités/bureaux.</button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                    :
                    this.state.client != null ?
                        <>
                        </>
                        :
                        null
            }
        </div>
    }
})));