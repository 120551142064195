import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const useAutoLogout = (timeoutDuration, data) => {
    const history = useHistory();
    const inactivityTimeout = useRef(null);

    const signout = () => {
        if (data) {
            data.username = "";
            data.config = null;
            data.isMenuOpen = false;
            window.localStorage.removeItem("jwt");
            history.replace('/signin');
        }
    };

    const resetInactivityTimer = () => {
        clearTimeout(inactivityTimeout.current);
        inactivityTimeout.current = setTimeout(signout, timeoutDuration);
    };

    useEffect(() => {
        window.onload = resetInactivityTimer;
        document.onmousemove = resetInactivityTimer;
        document.onkeypress = resetInactivityTimer;
        document.onclick = resetInactivityTimer;

        // Clean up the event listeners on component unmount
        return () => {
            clearTimeout(inactivityTimeout.current);
            window.onload = null;
            document.onmousemove = null;
            document.onkeypress = null;
            document.onclick = null;
        };
    }, [timeoutDuration]);

    return resetInactivityTimer; // If you still want the option to reset manually
};

export default useAutoLogout;