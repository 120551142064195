import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import ApiWrapper from '../utils/api-wrapper';

export default inject("data")(withRouter(observer(class Workflow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rules: null,
            selectedRule: null,
            errorMessage: null
        }

        this.cId = 0;
        this.aId = 0;
        this.valId = 0;
    }

    componentDidMount() {
        ApiWrapper.fetch(this, "/rules", "GET", {},
            response => {

                let rules = response.data != null ? response.data : [];
                rules.forEach(r => {
                    r.saved = true;

                    r.actions.forEach(a => {
                        if (a["type"] === "split_items") {
                            a.value.forEach(v => {
                                if (this.valId < v.id) {
                                    this.valId = v.id + 1;
                                }
                            })
                        }
                    })

                    r.conditions.forEach(c => {
                        c.id = ++this.cId;
                    })

                    r.actions.forEach(a => {
                        a.id = ++this.aId;
                    })
                })

                rules.sort((a, b) => {
                    return (a.priority ?? 1) - (b.priority ?? 1)
                });

                this.setState({
                    rules: rules,
                    selectedRule: null
                });
            },
            error => {
                this.setState({
                    errorMessage: "Error"
                })
            })
    }

    addRule() {
        let ruleId = 1;
        let rules = this.state.rules;

        rules.forEach(r => {
            if (ruleId <= r.id) {
                ruleId = r.id + 1;
            }
        })

        let rule = {
            active: true,
            id: ruleId,
            description: "",
            name: "<" + this.props.data.t.read("rule.rule").toLowerCase() + ">",
            conditions: [],
            actions: [],
            saved: false,
        };

        rules.push(rule);

        this.setState({
            rules: rules,
            selectedRule: rule
        }, () => {
            this.addCondition();
            this.addAction();
        })
    }

    addCondition() {
        let rule = this.state.selectedRule;
        let c = {
            id: ++this.cId,
            type: "amount_greater",
            value: 1000,
        }
        rule.conditions.push(c);
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    addAction() {
        let rule = this.state.selectedRule;
        let a = {
            id: ++this.aId,
            type: "send_email",
            value: "",
        }
        rule.actions.push(a);
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    updateRule(property, event) {
        let rule = this.state.selectedRule;

        if (property === "active") {
            rule.active = event.target.value === "1"
        }
        else if (property === "priority") {
            rule.priority = parseInt(event.target.value)
        }
        else {
            rule[property] = event.target.value;
        }

        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    updateCondition(property, c, event) {
        let rule = this.state.selectedRule;
        rule.saved = false;

        rule.conditions.forEach(t => {
            if (c.id === t.id) {
                t[property] = event.target.value;

                if (property === "type" && event.target.value === "invoice_billable") {
                    t["value"] = "1";
                }
            }
        })

        this.setState({
            rule: rule
        });
    }

    removeCondition(c) {
        let rule = this.state.selectedRule;
        rule.conditions.splice(rule.conditions.indexOf(c), 1);
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    updateAction(property, a, event) {
        let rule = this.state.selectedRule;

        rule.actions.forEach(t => {
            if (a.id === t.id) {
                if (property === "type" && event.target.value === "split_items") {
                    if (!Array.isArray(t["value"])) {
                        let val = [this.getSplitItemValue()];
                        t["value"] = val;
                    }
                }
                else {
                    t["value"] = "";
                }

                t[property] = event.target.value;
            }
        })
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    removeAction(c) {
        let rule = this.state.selectedRule;
        rule.actions.splice(rule.actions.indexOf(c), 1);
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    getSplitItemValue() {
        return {
            id: ++this.valId,
            percent: 100,
            updateInfo: "",
            updateInfoValue: "",
            updateCharacterAmount: null,
            replaceAtTheStart: false,
            maxAmount: ""
        }
    }

    addSplitItemValue(action) {
        let rule = this.state.selectedRule;

        rule.actions.forEach(t => {
            if (action.id === t.id) {
                let val = t.value;
                val.push(this.getSplitItemValue());
                t["value"] = val;
            }
        })
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    updateSplitItemValue(action, line, property, e) {
        var str2bool = (value) => {
            if (value && typeof value === "string") {
                if (value.toLowerCase() === "true") return true;
                if (value.toLowerCase() === "false") return false;
            }
            return value;
        }

        let rule = this.state.selectedRule;

        rule.actions.forEach(t => {
            if (action.id === t.id) {
                t["value"].forEach(v => {
                    if (v === line) {
                        v[property] = str2bool(e.target.value);
                    }
                })
            }
        })
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    removeSplitItemValue(action, line) {
        let rule = this.state.selectedRule;

        rule.actions.forEach(t => {
            if (action.id === t.id) {
                let val = t.value;
                val = val.filter(v => line !== v);
                t["value"] = val;
            }
        })
        rule.saved = false;

        this.setState({
            rule: rule
        });
    }

    handleSaveRule(event) {
        event.preventDefault();

        let data = new FormData();
        let tmp = JSON.stringify(this.state.selectedRule);
        tmp = JSON.parse(tmp);
        delete tmp.saved
        tmp.conditions.forEach(c => {
            delete c.id
        })
        tmp.actions.forEach(a => {
            delete a.id
        })
        data.append("rule", JSON.stringify(tmp));

        ApiWrapper.fetch(this, "/rule", "POST", data,
            response => {
                let rule = this.state.selectedRule;
                rule.saved = true;
                if (!rule._id && response.data)
                    rule._id = response.data

                this.setState({
                    rule: rule
                });
            },
            error => {
                this.setState({
                    errorMessage: "Error"
                })
            })
    }

    deleteRule() {
        const deleleFromList = () => {
            let rules = this.state.rules;
            rules.splice(rules.indexOf(this.state.selectedRule), 1);

            this.setState({
                rules: rules,
                selectedRule: null
            })
        }

        if (window.confirm("Delete?")) {
            if (this.state.selectedRule._id != null) {

                ApiWrapper.fetch(this, "/rule?id=" + this.state.selectedRule._id, "DELETE", {},
                    response => {
                        deleleFromList();
                    },
                    error => {
                        this.setState({
                            errorMessage: "Error"
                        })
                    })
            }
            else {
                deleleFromList();
            }
        }
    }

    render() {
        return <div>
            {
                this.state.rules != null ?
                    <div className="workflow-page row">
                        <div id="workflow-rules">
                            {
                                this.state.rules.map(r => {
                                    return <button key={r.id} className={"btn btn-secondary " + (r.active ? "" : " faded ") + (this.state.selectedRule != null && this.state.selectedRule.id === r.id ? "btn-info" : "")} onClick={() => this.setState({ selectedRule: r })}>
                                        {r.name} {!r.saved ? "*" : ""}
                                    </button>
                                })
                            }
                            <button className="btn btn-primary add-rule" onClick={() => this.addRule()}>+ {this.props.data.t.read("rule.rule")}</button>
                        </div>
                        <div id="workflow-diagram">
                            <div className="alert-section">
                                {
                                    this.state.errorMessage != null ?
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <form className="" method="post" onSubmit={(e) => { this.handleSaveRule(e) }}>
                                {
                                    this.state.selectedRule != null ?
                                        <div id="rule-container">
                                            <div>
                                                <div className="mb-2">
                                                    <div className="workflow-line row">
                                                        <label className="col-2">
                                                            {this.props.data.t.read("rule.name")}:
                                                        </label>
                                                        <div className="col-4">
                                                            <input type="text" onFocus={e => e.target.select()} required className="form-control" placeholder={this.props.data.t.read("rule.name")} value={this.state.selectedRule.name} onChange={(e) => this.updateRule("name", e)} />
                                                        </div>
                                                    </div>
                                                    <div className="workflow-line row">
                                                        <label className="col-2">
                                                            {this.props.data.t.read("rule.status")}:
                                                        </label>
                                                        <div className="col-2">
                                                            <select className="form-control" value={this.state.selectedRule.active ? "1" : "0"} onChange={(e) => this.updateRule("active", e)}>
                                                                <option value="1">{this.props.data.t.read("rule.active")}</option>
                                                                <option value="0">{this.props.data.t.read("rule.inactive")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="workflow-line row">
                                                        <label className="col-2">
                                                            {this.props.data.t.read("rule.priority")}:
                                                        </label>
                                                        <div className="col-1">
                                                            <input type="number" min="1" max="100" className="form-control" value={this.state.selectedRule.priority ?? 1} onChange={e => this.updateRule("priority", e)} />
                                                        </div>
                                                    </div>
                                                    <div className="workflow-line row">
                                                        <label className="col-2">
                                                            Description :
                                                        </label>
                                                        <div className="col-6">
                                                            <textarea type="text" required className="form-control" placeholder="Description" value={this.state.selectedRule.description} onChange={(e) => this.updateRule("description", e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>
                                                    Conditions
                                                    <button type="button" className="ml-3 btn btn-sm btn-primary" onClick={() => this.addCondition()}>+</button>
                                                </h3>
                                                <div className="mb-1">
                                                    {this.props.data.t.read("rule.after_invoice_complete")}...
                                                </div>
                                                {
                                                    this.state.selectedRule.conditions.map((c, idx) => {

                                                        return <div key={"c-" + c.id} className="mb-2">
                                                            <div className="workflow-line row">
                                                                <label className="col-1">
                                                                    {
                                                                        idx > 0 && this.state.selectedRule.conditions.length > 1 ?
                                                                            this.props.data.t.read("rule.and") + " "
                                                                            :
                                                                            null
                                                                    }
                                                                    {this.props.data.t.read("rule.if")}...
                                                                </label>
                                                                <div className="col-4">
                                                                    <select className="form-control" value={c.type} onChange={(e) => this.updateCondition("type", c, e)}>
                                                                        <option value="amount_lower">... {this.props.data.t.read("rule.the_amount_is_lower")} ...</option>
                                                                        <option value="amount_greater">... {this.props.data.t.read("rule.the_amount_is_greater")} ...</option>
                                                                        <option value="supplier_number">... {this.props.data.t.read("rule.the_supplier_number_equals")} ...</option>
                                                                        <option value="supplier_number_not_equal">... {this.props.data.t.read("rule.the_supplier_number_not_equals")} ...</option>
                                                                        <option value="cost_type">... {this.props.data.t.read("rule.the_cost_type_equals")} ...</option>
                                                                        <option value="cost_type_not_equal">... {this.props.data.t.read("rule.the_cost_type_not_equals")} ...</option>
                                                                        <option value="client_number">... {this.props.data.t.read("rule.the_client_number_equals")} ...</option>
                                                                        <option value="gl_unit_number">... {this.props.data.t.read("rule.the_gl_unit_number_equals")} ...</option>
                                                                        <option value="client_number_not_equal">... {this.props.data.t.read("rule.the_client_number_not_equals")} ...</option>
                                                                        <option value="invoice_billable">... {this.props.data.t.read("rule.invoice_is_set_as")} ...</option>
                                                                        <option value="invoice_custom">... {this.props.data.t.read("rule.invoice_is_custom")} ...</option>
                                                                        <option value="supplier_name_equals">... {this.props.data.t.read("rule.supplier_name_equals")} ...</option>
                                                                        <option value="gl_unit_is">... {this.props.data.t.read("rule.gl_unit_is")} ...</option>
                                                                        <option value="gl_unit_is_not">... {this.props.data.t.read("rule.gl_unit_is_not")} ...</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-7 row">
                                                                    <div className="col-4">
                                                                        {
                                                                            c.type === "invoice_billable" ?
                                                                                <select className="form-control" value={c.value} onChange={(e) => this.updateCondition("value", c, e)}>
                                                                                    <option value="1">{this.props.data.t.read("rule.billable")}</option>
                                                                                    <option value="0">{this.props.data.t.read("rule.non_billable")}</option>
                                                                                </select>
                                                                                :
                                                                                c.type === "invoice_custom" ?
                                                                                    <select className="form-control" value={c.value} onChange={(e) => this.updateCondition("value", c, e)}>
                                                                                        <option value="1">{this.props.data.t.read("rule.invoice_custom")}</option>
                                                                                        <option value="0">{this.props.data.t.read("rule.invoice_not_custom")}</option>
                                                                                    </select>
                                                                                    :
                                                                                    <input type="text" className="form-control" placeholder={this.props.data.t.read("rule.value")} value={c.value} onChange={(e) => this.updateCondition("value", c, e)} />
                                                                        }
                                                                    </div>
                                                                    <label className="col-7 font-xsmall">
                                                                        {
                                                                            c.type === "supplier_number" ?
                                                                                "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                                :
                                                                                c.type === "gl_unit_number" ?
                                                                                    "Ex: 14, or a list : 4, A, 14, Montreal"
                                                                                    :
                                                                                    c.type === "cost_type" || c.type === "cost_type_not_equal" ?
                                                                                        "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                                        :
                                                                                        c.type === "client_number" || c.type === "client_number_not_equal" ?
                                                                                            "Ex: XPENS0001, or a list : XP001, LR004, 3DRRR"
                                                                                            :
                                                                                            null
                                                                        }
                                                                    </label>
                                                                    {
                                                                        this.state.selectedRule.conditions.length > 1 ?
                                                                            <div className="col-1 text-right">
                                                                                <button className="btn btn-danger" onClick={() => this.removeCondition(c)}>-</button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                                <h3>
                                                    Actions
                                                    <button className="ml-3 btn btn-sm btn-primary" type="button" onClick={() => this.addAction()}>+</button>
                                                </h3>
                                                {
                                                    this.state.selectedRule.actions.map((c, idx) => {
                                                        return <div key={"a-" + c.id} className="mb-1">
                                                            <div className="workflow-line row">
                                                                <label className="col-1">
                                                                    {
                                                                        idx > 0 && this.state.selectedRule.actions.length > 1 ?
                                                                            this.props.data.t.read("rule.and")
                                                                            :
                                                                            this.props.data.t.read("rule.then")
                                                                    }

                                                                </label>
                                                                <div className="col-3">
                                                                    <select className="form-control" value={c.type} onChange={(e) => this.updateAction("type", c, e)}>
                                                                        <option value="send_email">... {this.props.data.t.read("rule.send_email_to")} ...</option>
                                                                        <option value="split_items">... {this.props.data.t.read("rule.split_items")} ...</option>
                                                                        <option value="only_keep_total">... {this.props.data.t.read("rule.only_keep_total")} ...</option>
                                                                        <option value="change_currency">... {this.props.data.t.read("rule.change_currency_for")} ...</option>
                                                                        <option value="change_all_totals">... {this.props.data.t.read("rule.change_all_totals")} ...</option>
                                                                        <option value="change_user">... {this.props.data.t.read("rule.change_user")} ...</option>
                                                                    </select>
                                                                </div>
                                                                {
                                                                    c.type === "send_email" ?
                                                                        <div className="col-7 row">
                                                                            <div className="col-4">
                                                                                <input type="email" required className="form-control" placeholder={this.props.data.t.read("rule.email")} value={c.value} onChange={(e) => this.updateAction("value", c, e)} />
                                                                            </div>
                                                                            <label className="col-8 font-xsmall">
                                                                                Ex: me@my-company.com
                                                                            </label>
                                                                        </div>
                                                                        :
                                                                        c.type === "change_currency" ?
                                                                            <div className="col-7 row">
                                                                                <div className="col-4">
                                                                                    <input type="text" required className="form-control" placeholder={this.props.data.t.read("rule.currency")} value={c.value} onChange={(e) => this.updateAction("value", c, e)} />
                                                                                </div>
                                                                                <label className="col-8 font-xsmall">
                                                                                    Ex: CAD
                                                                                </label>
                                                                            </div>
                                                                            :
                                                                            c.type === "split_items" ?
                                                                                <div className="col-7">
                                                                                    <div>
                                                                                        {
                                                                                            c.value.map((line, idx) => {
                                                                                                return <div key={"l-" + line.id} className="mb-2 border p-1">
                                                                                                    <div className="row">
                                                                                                        <div className="col-11">
                                                                                                            <div className="row mb-1">
                                                                                                                <div className="col-4 font-weight-bold">
                                                                                                                    {
                                                                                                                        idx === 0 ?
                                                                                                                            this.props.data.t.read("rule.a_line_containing")
                                                                                                                            :
                                                                                                                            this.props.data.t.read("rule.and_add_line")
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="col-3">
                                                                                                                    <div className="input-group input-group-sm">
                                                                                                                        <div className="input-group-prepend">
                                                                                                                            <span className="input-group-text">%</span>
                                                                                                                        </div>
                                                                                                                        <input type="number" min="-100" max="1000" value={line.percent} onChange={e => this.updateSplitItemValue(c, line, "percent", e)} style={{ maxWidth: "100%" }} className="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-3">
                                                                                                                    {this.props.data.t.read("rule.of_amount")},
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row mb-1">
                                                                                                                <div className="col-4">
                                                                                                                    {this.props.data.t.read("rule.up_to_max_of")}
                                                                                                                </div>
                                                                                                                <div className="col-3">
                                                                                                                    <div className="input-group input-group-sm">
                                                                                                                        <div className="input-group-prepend">
                                                                                                                            <span className="input-group-text">$</span>
                                                                                                                        </div>
                                                                                                                        <input type="number" value={line.maxAmount} style={{ maxWidth: "100%" }} onChange={e => this.updateSplitItemValue(c, line, "maxAmount", e)} className="form-control" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-5">
                                                                                                                    ({this.props.data.t.read("rule.leave_empty_for_unlimited")})
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row mb-1">
                                                                                                                <div className="col-4">
                                                                                                                    {this.props.data.t.read("rule.and_replace")}
                                                                                                                </div>
                                                                                                                <div className="col-3">
                                                                                                                    <input type="number" min="-100" max="1000" value={line.updateCharacterAmount} onChange={e => this.updateSplitItemValue(c, line, "updateCharacterAmount", e)} style={{ maxWidth: "100%" }} className="form-control" />
                                                                                                                </div>
                                                                                                                <div className="col-3">
                                                                                                                    {this.props.data.t.read("rule.character_at")}
                                                                                                                </div>
                                                                                                                <div className="col-2">
                                                                                                                    <select className="form-control form-control-sm" value={line.replaceAtTheStart} onChange={e => this.updateSplitItemValue(c, line, "replaceAtTheStart", e)}>
                                                                                                                        <option value={false}>{this.props.data.t.read("rule.the_end")}</option>
                                                                                                                        <option value={true}>{this.props.data.t.read("rule.the_start")}</option>
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row mb-1">
                                                                                                                <div className="col-4">
                                                                                                                    {this.props.data.t.read("rule.of")}
                                                                                                                </div>
                                                                                                                <div className="col-3">
                                                                                                                    <select className="form-control form-control-sm" value={line.updateInfo} onChange={e => this.updateSplitItemValue(c, line, "updateInfo", e)}>
                                                                                                                        <option value="">--</option>
                                                                                                                        <option value="GLNatural">GLNatural</option>
                                                                                                                        <option value="GLOffice">GLOffice</option>
                                                                                                                        <option value="CostType">CostType</option>
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    line.updateInfo != null && line.updateInfo.length > 0 ?
                                                                                                                        <React.Fragment>
                                                                                                                            <div className="col-1">
                                                                                                                                {this.props.data.t.read("rule.by")}
                                                                                                                            </div>
                                                                                                                            <div className="col-3">
                                                                                                                                <input type="text" required className="form-control form-control-sm" placeholder="Value" value={line.updateInfoValue} onChange={e => this.updateSplitItemValue(c, line, "updateInfoValue", e)} />
                                                                                                                            </div>
                                                                                                                        </React.Fragment>
                                                                                                                        :
                                                                                                                        null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-1">
                                                                                                            {
                                                                                                                c.value.length > 1 ?
                                                                                                                    <button className="btn btn-danger" onClick={() => this.removeSplitItemValue(c, line)}>-</button>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        <button type="button" className="btn btn-success" onClick={() => this.addSplitItemValue(c)}>+ line</button>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                c.type === "change_all_totals" ?
                                                                                    <div className="input-group-prepend">
                                                                                        <input type="number" min="-100" max="100" value={c.value} onChange={e => this.updateAction("value", c, e)} style={{ maxWidth: "100%" }} className="form-control" />
                                                                                        <span className="input-group-text">%</span>
                                                                                    </div>
                                                                                    :
                                                                                    c.type === "change_user" ?
                                                                                        <div className="input-group-prepend">
                                                                                            <input type="text" value={c.value} onChange={e => this.updateAction("value", c, e)} />
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                }

                                                                {
                                                                    this.state.selectedRule.actions.length > 1 ?
                                                                        <div className="col-1 text-right">
                                                                            <button className="btn btn-danger" type="button" onClick={() => this.removeAction(c)}>-</button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className="mt-5 d-flex justify-content-between align-items-end">
                                                <div>
                                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => this.deleteRule()}>{this.props.data.t.read("rule.delete_rule")}</button>
                                                </div>
                                                <div>
                                                    <button type="submit" disabled={this.state.selectedRule.saved} className="btn btn-lg btn-primary">{this.props.data.t.read("rule.save_rule")}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </form>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    }
})));