import React, { Component} from 'react';
import {withRouter} from "react-router-dom";
import { observer, inject  } from "mobx-react";

export default inject("data")(observer(withRouter(class Modal extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            processingAction : false
        }
    }

    disableButtons() {
        this.setState({
            processingAction : true
        })
    }

    processOk() {
    	this.disableButtons();
    	let valid = this.props.okAction()

    	if (valid === false) {
        	this.setState({
        		processingAction : false
      		})
      	}
    }

    render() {
        return <div className="modal d-block" tabIndex="-1" role="dialog">
        	<div className="modal-dialog" role="document" style={{maxWidth : this.props.width}}>
          		<div className="modal-content" style={{width : this.props.width}}>
            		<div className="modal-header">
              			<h4 className="modal-title d-flex justify-content-between" style={{width:"100%"}}>
							  <span>
							  	{this.props.title}
							  </span>
							  {
									this.props.titleAction != null ?
										<button type="button" disabled={this.state.processingAction} className="btn btn-sm btn-secondary" onClick={() => {this.disableButtons();this.props.titleAction()} }>
											{this.props.titleText}
										</button>
									:
										null
								}
							</h4>
            		</div>
            		<div className="modal-body" style={{maxHeight:"70vh", position: "relative", overflow:this.props.overflow? this.props.overflow: "auto"}}>
						{
							this.state.processingAction ?
								<i>
									{this.props.data.t.read("please_wait")}...
								</i>
							:
								this.props.children
						}
            		</div>
            		<div className="modal-footer d-flex justify-content-between">
						{
							this.props.cancelAction != null ?
								<button type="button" disabled={this.state.processingAction} className="btn btn-outline-dark" onClick={() => {this.disableButtons();this.props.cancelAction()} }>{this.props.cancelText}</button>
							:
								null
						}
						{
							this.props.okAction != null ?
								<button type="button" disabled={this.state.processingAction || this.props.invalid} className="btn btn-primary" onClick={() => {this.processOk()} }>{this.props.okText}</button>
							:
								null
						}
						{
							this.props.okIsSubmit ?
								<button type="submit" disabled={this.state.processingAction} className="btn btn-primary">{this.props.okText}</button>
							:
								null
						}
            		</div>
          		</div>
        	</div>
	    </div>
    }
})));