import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import {withRouter} from "react-router-dom";

export default inject("data")(withRouter(observer(class Header extends Component  {

    render() {
        return  <header>
                </header>
    }
})));