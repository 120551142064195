import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';

import logo from '../assets/img/logo-xpens.svg';
import DragAndDrop from '../components/drag-drop';
import uploadIcon from '../assets/img/icon-upload.svg';
import fileIcon from '../assets/img/icon-file.svg';
import closeIcon from '../assets/img/icon-close.svg';
import fileUploadedIcon from '../assets/img/icon-file-uploaded.svg';
import uploadImageIcon from '../assets/img/icon-uploading.svg';

export default inject("data")(observer(class NewInvoice extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            files : [],
            uploading : false,
            errors : [],
            success : [],
            step : "init",
            currentUploadStr : null
        }

        this.errorInvoices = [];
        this.successInvoices = [];
    }

    componentDidMount() {
        this.props.data.hideMenu = true;
    }

    componentWillUnmount() {
        this.props.data.hideMenu = false;
    }

    goBack(){
        this.props.history.goBack();
    }

    handleDrop(files) {
        let fileList = this.state.files;
        let errors = [];

        for (let i = 0; i < files.length; i++) {
            if (files[i].name) {
                let mustAdd = true;

                if (mustAdd) {
                    mustAdd = files[i].type.indexOf("application/pdf") !== -1 ||
                              files[i].type.indexOf("image/png") !== -1 ||
                              files[i].type.indexOf("image/jpeg") !== -1;

                    if (!mustAdd) {
                        errors.push(this.props.data.t.read("invalid_upload_file_type"));
                    }
                }

                if (mustAdd) {
                    fileList.forEach(f => {
                        if (f.name === files[i].name) {
                            mustAdd = false;
                        }
                    })
                }

                if (mustAdd) {
                    fileList.push(files[i]);
                }
            }
        }

        this.setState({
            files: fileList,
            errors : errors
        });
    }

    removeFile(file) {
        let fileList = this.state.files;
        fileList.splice(this.state.files.indexOf(file), 1);

        this.setState({
            files: fileList,
            errors : []
        });
    }

    sendInvoice() {
        let file = this.state.files[0];

        this.setState({
            currentUploadStr : file.name + " (" + (file.size/1000/1000).toFixed(2) + " " + this.props.data.t.read("mb_unit") + ")"
        }, () => {
            const data = new FormData();

            if (file.type.indexOf("application/pdf") !== -1) {
                data.append("pdf", file);
                this.processNewInvoice(file.name, data);
            }
            else {
                data.append("image", file);
                this.processNewInvoice(file.name, data);
            }
        })
    }

    uploadResult() {
        if (this.state.files.length > 0) {
            this.sendInvoice();
        }
        else {
            this.setState({
                step : "completed",
                errors : this.errorInvoices,
                success : this.successInvoices,
            }, () => {
                this.errorInvoices = [];
                this.successInvoices = [];
            });
        }
    }

    processNewInvoice(name, data) {
        let files = this.state.files;

        ApiWrapper.fetch(this, "/invoices", "POST", data,
            response => {
                files.splice(0, 1);
                this.successInvoices.push(name);

                this.setState({
                    files : files
                }, () => {
                    this.uploadResult();
                })
            },
            response => {
                files.splice(0, 1);
                let extraMsg = name;

                if (response.response.data != null) {
                    extraMsg += " : " + response.response.data.message;
                }

                this.errorInvoices.push(extraMsg);

                this.uploadResult();
            }, true)
    }

    submitInvoices() {
        this.setState({
            step : "uploading",
            errors : []
        }, () => {
            this.successInvoices = [];
            this.errorInvoices = [];
            this.sendInvoice();
        });
    }

    render() {
        return  <div className="new-invoice">
                    <div className="pointer position-absolute text-primary pointer" style={{left : 20, top : 20}}>
                        <img src={logo} alt="XPENS logo" />
                    </div>
                    <div className="pointer position-absolute text-primary pointer" style={{right : 20, top : 20}} onClick={() => this.goBack()}>
                        {this.props.data.t.read("back")}
                    </div>
                    <div className="page-centered">
                        {
                            this.state.step === "uploading" ?
                                <div className="uploading-container">
                                    <div>
                                        <img src={uploadImageIcon} alt="Uploading" />
                                    </div>
                                    <div className="font-weight-bold font-large">
                                        {this.props.data.t.read("uploading")}
                                    </div>
                                    <div className="font-small semi-faded">
                                        {this.state.currentUploadStr}
                                    </div>
                                </div>
                            :
                            this.state.step === "completed" ?
                                <div className="uploading-container">
                                    {
                                        this.state.errors.length > 0 ?
                                            <div>
                                                <div className="alert alert-warning" role="alert">
                                                    {
                                                        this.state.errors.map((err, i) => {
                                                            return <div key={"err-" + i}>
                                                                {err}
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            null
                                    }
                                    <div>
                                        <img src={fileUploadedIcon} alt="File Uploaded" />
                                    </div>
                                    <div className="font-weight-bold mt-3">
                                        {this.props.data.t.read("upload_completed")}
                                    </div>
                                    <div className="font-small semi-faded mb-5">
                                        {this.props.data.t.read("invoice_s") + " "}
                                        {
                                            this.state.files.map(file => {
                                                return <span key={file.name} className="mr-1">
                                                    {file.name}
                                                </span>
                                            })
                                        }
                                        {" " + this.props.data.t.read("invoice_submitted")}
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn btn-primary btn-no-border-radius btn-primary-shadow pl-5 pr-5" onClick={() => this.goBack()}>
                                            {this.props.data.t.read("done")}
                                        </button>
                                    </div>
                                </div>
                            :
                                <DragAndDrop handleDrop={files => this.handleDrop(files)}>
                                        {
                                            this.state.files.length > 0 ?
                                                <div className="new-files-container">
                                                    <div className="mb-auto full-width">
                                                        {
                                                            this.state.files.map(file => {
                                                                return <div key={file.name} className="invoice-item d-flex justify-content-between mb-3">
                                                                            <div className="invoice-file-info">
                                                                                <div className="font-weight-bold">
                                                                                    {file.name}
                                                                                </div>
                                                                                <div className="font-small semi-faded">
                                                                                    {(file.size/1000/1000).toFixed(2)} {this.props.data.t.read("mb_unit")}
                                                                                    <span className="ml-1">
                                                                                        {
                                                                                            file.type.indexOf("application/pdf") !== -1 ?
                                                                                                "PDF"
                                                                                            :
                                                                                            file.type.indexOf("image/png") !== -1 ?
                                                                                                "PNG"
                                                                                            :
                                                                                            file.type.indexOf("image/jpeg") !== -1 ?
                                                                                                "JPG"
                                                                                            :
                                                                                                "N/A"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <img src={closeIcon} alt="Close" className="ml-4 pointer" onClick={() => this.removeFile(file)} />
                                                                    </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="invoice-item p-0">
                                                        <label className="pointer p-3" style={{width:"100%", height:"100%", display:"block"}}>
                                                            <img src={fileIcon} alt="New invoice" className="mr-4" />
                                                            {this.props.data.t.read("add_another_invoice")}
                                                            <input type="file" style={{display:"none"}} multiple onChange={evt => this.handleDrop(evt.target.files) } />
                                                        </label>
                                                    </div>
                                                    <div className="mt-4">
                                                        <button className="btn btn-primary btn-no-border-radius btn-primary-shadow pl-5 pr-5" onClick={() => this.submitInvoices()}>
                                                            {this.props.data.t.read("submit")}
                                                        </button>
                                                    </div>
                                                </div>
                                            :
                                                <div className="first-files-container">
                                                    <div>
                                                        <img src={uploadIcon} alt="XPENS logo" />
                                                    </div>
                                                    <div className="font-weight-bold mt-3">
                                                        {this.props.data.t.read("upload_new_invoice")}
                                                    </div>
                                                    <div className="font-small semi-faded mb-5">
                                                        {this.props.data.t.read("browse_files")}
                                                    </div>
                                                    <div>
                                                        <label className="btn btn-primary btn-no-border-radius btn-primary-shadow pl-5 pr-5">
                                                            {this.props.data.t.read("browse")}
                                                            <input type="file" style={{display:"none"}} multiple onChange={evt => this.handleDrop(evt.target.files) } />
                                                        </label>
                                                    </div>
                                                </div>
                                        }
                                </DragAndDrop>
                            }
                    </div>
                </div>
    }
}));