import { useEffect, useState } from 'react';
import ApiWrapper from "../utils/api-wrapper";

import axios from 'axios';
import { faAngleDoubleLeft, faAngleDoubleRight, faArrowDown, faArrowUp, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableData = (props) => {
    const source = axios.CancelToken.source();
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [searchStr, setSearchStr] = useState("");
    const [pageSize, setPageSize] = useState(props.pageSize ? props.pageSize : 5);
    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState(props.filters ? props.filters : []);
    const [globalfilter, setGlobalfilter] = useState("");
    const [dirtyGlobalfilter, setDirtyGlobalfilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataset, setDataset] = useState(null);
    const columnWidth = `${100 / props.availableColumns.length}%`;

    const toggleFilter = (filter) => {
        const result = filters.find(f => filter.id === f.id) === undefined ? [...filters, filter] : filters.filter(f => filter.id !== f.id);
        setFilters(result);
    }
    const search = (filter) => {
        const result = [...filters.filter(f => filter.id !== f.id), filter];
        setFilters(result);
    }

    const handleSelection = (inv, availableColumn) => {
        if(["vendors", "gl"].includes(availableColumn.displayName))
            return props.selection({ "url": "dataset/invoices", "filters": [{ "id": availableColumn.filter, "value": inv._id }, { "id": "status", "value": "Finalized" }], "title": availableColumn.evaluate(inv) })
        
        if(availableColumn.displayName === "invoices")
            return props.selection(inv._id)
        
        return  null;
    }

    const sortTable = (name) => {
        if (loading) {
            return;
        }
        const sorter = sorting.find(s => s.id === name)

        if (sorter === undefined)
            return setSorting([{ "id": name, "desc": true }])

        if (sorter.desc)
            return setSorting([{ "id": name, "desc": false }])
        else
            return setSorting([])
    }

    const fetchData = () => {
        setLoading(true);
        let start = page * pageSize;

        const searchData = `?start=${encodeURIComponent(start)}&page_size=${encodeURIComponent(pageSize)}&globalfilter=${encodeURIComponent(globalfilter ?? '')}&filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        ApiWrapper.fetch(null, `${props.url}/${searchData}`, "GET", null, (response) => {
            setDataset(response.data.dataset);
            setLoading(false);
            setTotalRowCount(response.data.meta.totalRowCount);
        },
            null, false, source.token);
    }

    useEffect(() => {
        fetchData();
    }, [page, sorting, filters])

    useEffect(
        () => () => source.cancel('table data unmounted')
        , []);

    return props.availableColumns && dataset ? <div className={props.className ? props.className : 'card col m-2 min-height-45 border-radius-30'} >
        <div className="card-body">
            <div className='row'>
                {props.availableColumns.filter(c => c.searchable === true).map((availableColumn) => (
                    <input key={`header-${availableColumn.displayName}}-search`} type="text" className="form-control search-field" placeholder={props.t.read(availableColumn.displayName)} onChange={e => setSearchStr(e.target.value)} onKeyUp={ () => search({"id": availableColumn.name, "value": searchStr})} />
                ))}
            </div>
            <div className='row'>
                {props.availableColumns.map((availableColumn) => (
                    <div onClick={() => sortTable(availableColumn.name)} // Set the width dynamically
                        style={{ width: columnWidth }} className='pointer one-line p-1' key={`header-${availableColumn.displayName}}`}>
                        <span className='font-weight-bold' data-bs-toggle="tooltip"  title={availableColumn.acronym && props.t.read(availableColumn.acronym)} >
                            {props.t.read(`${availableColumn.displayName}`)}
                            {sorting.find(s => s.id === availableColumn.name) && <FontAwesomeIcon className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"} icon={sorting.find(s => s.id === availableColumn.name).desc ? faArrowDown : faArrowUp} />}
                        </span>
                    </div>
                ))}
            </div>
            {dataset.map(inv => <div className='row' key={`${inv._id}-list`}>
                {props.availableColumns.map(availableColumn => <div style={{ width: columnWidth }}
                    className={["vendors", "gl", "invoices"].includes(availableColumn.displayName) ? 'pointer text-underline text-blue one-line p-1' : 'one-line p-1'} key={`${inv._id}-${availableColumn.displayName}-value`} onClick={() => handleSelection(inv, availableColumn)} >
                    <span className='font-small'>
                        {availableColumn.money && !isNaN(availableColumn.evaluate(inv)) ? `$${availableColumn.evaluate(inv).toLocaleString(`${props.t.lang}-${props.t.lang.toUpperCase()}`, { maximumFractionDigits: 2 , minimumFractionDigits: 2 })}` : availableColumn.evaluate(inv)}
                    </span>
                </div>)}
            </div>
            )}
        </div>
        <div className='d-flex justify-content-between p-2'>
            <div>
                <span className='font-italic'>
                    {page * pageSize + 1}-{page * pageSize + pageSize <= totalRowCount ? page * pageSize + pageSize : totalRowCount} {props.t.read("rule.of")}
                </span>
                <span className='p-1 text-primary'>
                    {totalRowCount}
                </span>
            </div>
            <div>
                <button className='btn  btn-sm' disabled={loading || page === 0}>
                    <FontAwesomeIcon key={"left-arrow-double"} className='fa-xs' onClick={() => setPage(0)} icon={faAngleDoubleLeft} />
                </button>
                <button className='btn  btn-sm' disabled={loading || page === 0}>
                    <FontAwesomeIcon key={"left-arrow"} className='fa-xs' onClick={() => setPage(page - 1)} icon={faChevronLeft} />
                </button>
                <span key={`page-${page}`} className={'active text-primary'}>
                    <span key={`page-n-${page}`} className='p-2'>{page+1}</span>
                </span>
                <button className='btn btn-sm' disabled={loading || page + 1 === Math.ceil(totalRowCount / pageSize) }>
                    <FontAwesomeIcon key={"right-arrow"} className='fa-xs' onClick={() => setPage(page + 1)} icon={faChevronRight} />
                </button>
                <button className='btn btn-sm' disabled={loading || page + 1 === Math.ceil(totalRowCount / pageSize) }>
                    <FontAwesomeIcon key={"right-arrow-double"} className='fa-xs' onClick={() => setPage(Math.ceil(totalRowCount / pageSize) - 1)} icon={faAngleDoubleRight} />
                </button>
            </div>
        </div>
        {props.showButton &&
            <div className='d-flex justify-content-around mb-2 rounded-lg'>
                <button type="button" onClick={() => props.close()} className="btn btn-secondary btn-primary-shadow">
                    {props.t.read("back")}
                </button>
                <button disabled={loading} onClick={() => toggleFilter({ "id": "status", "value": "Finalized" })} className=' btn btn-secondary'>
                   {filters.find(f => "status" === f.id) !== undefined ? "": "Non"} {props.t.read("status.finalized")}
                </button>
            </div>
        }
    </div> :
        null
}

export default TableData;