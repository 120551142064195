const availableColumns = [
    { "name": "created_at", "evaluate": inv => (new Date(inv.created_at)).toLocaleString(), "displayName": "created_at" },
    { "name": "updated_at", "evaluate": inv => (new Date(inv.updated_at)).toLocaleString(), "displayName": "updated_at" },
    { "name": "json.InvoiceDate", "evaluate": inv => inv.json ? inv.json.InvoiceDate : "", "displayName": "billing_date" },
    { "name": "json.DueDate", "evaluate": inv => inv.json?.DueDate ? inv.json.DueDate : "", "displayName": "due_date" },
    //{ "name": "json.ListSupplier[0].Number", "evaluate": inv => inv.json?.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Number : "", "displayName": "supplier_number" },
    //{ "name": "json.ListSupplier[0].Name", "evaluate": inv => inv.json?.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Name : "", "displayName": "supplier_name" },
    { "name": "json.InvoiceNo", "evaluate": inv => inv.json ? inv.json.InvoiceNo : "", "displayName": "invoice_number" },
    { "name": "json.Currency", "evaluate": inv => inv.json ? inv.json.Currency : "", "displayName": "currency" },
    //{ "name": "status", "evaluate": inv => inv.status, "displayName": "status" },
    { "name": "json.Total", "evaluate": inv => inv.json?.Total ? `$${inv.json.Total.toFixed(2)}` : "", "displayName": "total" },
    { "name": "json.TotalWithTax", "evaluate": inv => inv.json?.TotalWithTax ? `$${inv.json.TotalWithTax}` : "", "displayName": "total_with_tax" },
    //{ "name": "json.GLUnit", "evaluate": inv => inv.json?.GLUnit ? inv.json.GLUnit : "", "displayName": "unit" },
    //{ "name": "username", "evaluate": inv => inv.username ? inv.username : "", "displayName": "username" },
    //{ "name": "approver", "evaluate": inv => inv.json?.approver ? `${inv.json.approver.first_name} ${inv.json.approver.last_name}` : "", "displayName": "approver" },
    //{ "name": "preview", "evaluate": inv => inv._id, "displayName": "preview"},
    //{ "name": "status_details", "evaluate": inv => inv.status_details, "displayName": "comments" }
  ]

  export default availableColumns;