import { useEffect, useState } from "react";
import ApiWrapper from "../utils/api-wrapper";
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Modal from './modal';
import { AccountNumber, PayloadInput, PaymentMethodForm, RoutingNumber } from "payload-react";

const PlCustomer = ({ data, formSupplierBankingInfos, setFormSupplierBankingInfos, fetcher }) => {
    const source = axios.CancelToken.source();

    const [steps] = useState([data.t.read("supplier_update"), data.t.read("create_payment_method")])
    const [activeStep, setActiveStep] = useState(formSupplierBankingInfos.supplier.pl_customer_id ? 1 : 0);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [saving, setSaving] = useState(false);
    const [client_token, setClientToken] = useState(null);

    const [isProcessing, setIsProcessing] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        if (activeStep === 1)
            generate_client_token();
    }, [activeStep])

    const generate_client_token = () => {
        setSaving(true);
        ApiWrapper.fetch(null, `/payments/authenticate/pl/`, "GET", null,
            (response) => {
                console.log(response.data.pl_client_token);
                setClientToken(response.data.pl_client_token);
                setSaving(false);
            },
            (error) => {
                setSaving(false);
            }, false, source.token);
    }

    const createCustomer = () => {
        setSaving(true);
        let formData = new FormData();
        formData.append("supplier", JSON.stringify(formSupplierBankingInfos.supplier));
        formData.append("email", email);
        formData.append("phone", phone);

        ApiWrapper.fetch(null, `/payments/supplier/pl`, "PUT", formData,
            (response) => {
                setSaving(false);
                const shallow = formSupplierBankingInfos;
                shallow.supplier.pl_customer_id = response.data.pl_customer_id;
                setFormSupplierBankingInfos(shallow);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            },
            (error) => {
                setSaving(false);
            }, false, source.token);
    }

    const updateSupplierPaymentMethod = (data) => {
        setSaving(true);
        let formData = new FormData();
        formData.append("supplier", JSON.stringify(formSupplierBankingInfos.supplier));
        formData.append("payment_method", JSON.stringify(data.payment_method));

        ApiWrapper.fetch(null, `/payments/supplier/payment_method/pl`, "PUT", formData,
            (response) => {
                setSaving(false);
                fetcher();
                setFormSupplierBankingInfos({});
            },
            (error) => {
                setSaving(false);
            }, false, source.token);
    }


    return <div className="card overlay" onClick={e => {
        if (e.target.tagName.toLowerCase() === 'button' && e.target.type === 'submit')
            return;
        setFormSupplierBankingInfos({})
    }
    }>
        <div className="overlay-content w-50" >
            <div className="card m-1 p-1 rounded-lg" onClick={e => {
                // If it's a submit button, allow propagation
                if (e.target.tagName.toLowerCase() === 'button' && e.target.type === 'submit')
                    return;
                e.preventDefault();
                e.stopPropagation()
            }
            }>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {errorMessage &&
                        <Modal title={data.t.read("error")} okAction={() => setErrorMessage(null)} okText={"ok"}>
                            <div className="text-danger">
                                {errorMessage}
                            </div>
                        </Modal>
                    }

                    {
                        activeStep === 0 && <div className="card m-1 p-1 rounded-lg" onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                            <div className="card-body m-5 p-0 ">
                                <div className="card-title mb-3">
                                    <h2>{formSupplierBankingInfos.supplier["names"][0]}</h2>
                                </div>
                                <div>
                                    <input className="form-control mb-1" type="email" id="email" name="email" placeholder="Email" required onChange={e => setEmail(e.target.value)} />
                                    <input className="form-control mb-1" type="text" id="phone" name={data.t.read("invoice.supplier_phone")} placeholder={data.t.read("invoice.supplier_phone")} onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-sm mr-2 pointer btn-primary" disabled={saving} onClick={() => createCustomer()}>
                                        {data.t.read("save")}
                                        {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        activeStep === 1 && client_token !== undefined && client_token !== null ?
                            <>
                                <PaymentMethodForm
                                    clientToken={client_token}
                                    className="container mt-5"
                                    styles={{ invalid: 'is-invalid' }}
                                    autoSubmit={false}

                                    onProcessing={(data) => {
                                        console.log("onProceed", data);
                                        setIsProcessing(true);
                                    }}
                                    onProcessed={(evt) => {
                                        console.log("onProcessed", evt);
                                        setIsProcessing(false);
                                    }}
                                    onError={(evt) => {
                                        console.log("onError", evt);
                                        setIsProcessing(false);
                                    }}
                                    onDeclined={(evt) => {
                                        console.log("onDeclined", evt);
                                        setIsProcessing(false);
                                    }}
                                    onCreated={(data) => {
                                        console.log("onCreated", data);
                                        setIsProcessing(false);
                                        updateSupplierPaymentMethod(data);
                                    }}

                                >
                                    <PayloadInput attr="customer_id" type="hidden" value={formSupplierBankingInfos.supplier.pl_customer_id} />
                                    <PayloadInput attr="account_holder" type="hidden" value={formSupplierBankingInfos.supplier.names[0]} />
                                    <PayloadInput attr="transfer_type" type="hidden" value="receive-only" />
                                    <div className="d-flex justify-content-center form-group px-1">
                                        <label className="col-3">{data.t.read("account_number")}</label>
                                        <AccountNumber className="form-control col-5"
                                            onInvalid={(evt) => {
                                                setErrorMessage(evt.message);
                                            }} />
                                    </div>
                                    <div className="d-flex justify-content-center form-group px-1">
                                        <label className="col-3">{data.t.read("routing_number")}</label>
                                        <RoutingNumber className="form-control col-5"
                                            onInvalid={(evt) => {
                                                setErrorMessage(evt.message);
                                            }} />
                                    </div>

                                    <div className="d-flex justify-content-center form-group px-1">
                                        <label className="col-3">{data.t.read("account_type")}</label>
                                        <select className="form-control pl-input col-5" pl-input="account_type">
                                            <option value="checking">{data.t.read("checking")}</option>
                                            <option value="savings">{data.t.read("savings")}</option>
                                        </select>
                                    </div>

                                    <div className="d-flex justify-content-center form-group px-1">
                                        <button className="btn btn-primary" type="submit" disabled={isProcessing}>
                                            {data.t.read("save")}
                                            {isProcessing && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                                            }
                                        </button>
                                    </div>
                                </PaymentMethodForm>
                            </>
                            :
                            null
                    }
                </Box>
            </div>
        </div>
    </div>
}

export default inject('data')(withRouter(observer(PlCustomer)));
